import React from 'react'
import { Container } from 'react-bootstrap'
import Footer from './commons/Footer'

const Home = () => {
  return (
    <>
      <Container>
        <main>
          <div class="contain">
            <div class="timeline">
              <ul>
                <li>
                  <div class="timeline-content">
                    <h3 class="date">2017</h3>
                    <h1>Okalihera</h1>
                    <p>
                      Okalihera is born; Fleet size: 3 tuktuks (later Oka Taxi)
                    </p>
                  </div>
                </li>
                <li>
                  <div class="timeline-content">
                    <h3 class="date">2019</h3>
                    <h1>Fleet</h1>
                    <p>Proof of concept (fleet: 13 tuktuks)</p>
                  </div>
                  <div class="timeline-content">
                    <h3 class="date">2019</h3>
                    <h1>Oka Freeze & Oka TV</h1>
                    <p>Start of Oka Freeze (9) & Oka TV (7)</p>
                  </div>
                </li>
                <li>
                  <div class="timeline-content">
                    <h3 class="date">2020</h3>
                    <h1>COVID 19</h1>
                    <p>.</p>
                  </div>
                </li>
                <li>
                  <div class="timeline-content">
                    <h3 class="date">2021</h3>
                    <h1>Taxi Fleet, Oka Freeze & Oka TV</h1>
                    <p>Oka Taxi fleet: 15</p>
                    <p>Oka Freeze: 10</p>
                    <p>Oka TV: 14</p>
                  </div>
                </li>
                <li>
                  <div class="timeline-content">
                    <h3 class="date">2022</h3>
                    <h1>Growth strategy development</h1>
                    <p></p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </main>
      </Container>
      <Footer />
    </>
  )
}

export default Home
