import React from 'react'
import { Container } from 'react-bootstrap'
import Home from './Home'

const WhatWeDo = () => {
  return (
    <Home />
  )
}

export default WhatWeDo
