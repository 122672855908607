import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Footer from './commons/Footer'
import Image from 'react-bootstrap/Image'
import './commons/styles.css'

const WhatWeDo = () => {
  return (
    <>
      <Container>
        <figure className="position-relative imgsize">
         
          <figcaption className=" fs-2 col-sm-6">
            Oka Ventures is locally established, locally owned, demonstrated
            resilience during various crisis and is striving to grow.
          </figcaption>
        </figure>

        <div class="container-lg py-5">
          <section draggable="false" class="container py-1" data-v-271253ee="">
            <section class="mb-10 text-center">
              <div class="col-md-12 mb-4 mb-md-0">
                <h3 class="fw-bold fs-1">Mission</h3>

                <p class="text-muted fs-3">
                  We provide hands on opportunities for entrepreneurs by renting
                  out specific hardware that enables them to starting up a local
                  business. We deduce the necessary hardware from market
                  analysis and willingness to pay studies in rural communities,
                  which increases their probability of success..
                </p>
              </div>
            </section>
          </section>
        </div>

        <div class="container-lg ">
          <section draggable="false" class="container pt-5">
            <section class="mb-10 text-center">
              <h2 class="fw-bold mb-5">
                <span>Partners and Funders</span>{' '}
              </h2>
              <div class="row gx-lg-12">
                <section class="section section-default mt-none mb-none">
                  <div class="container">
                    <div class="row">
                      <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="square-holder">
                          <a
                            href="https://www.solidarmed.ch/en/"
                            target="_blank"
                          >
                            <img
                              alt=""
                              src="https://firebasestorage.googleapis.com/v0/b/oka-ventures.appspot.com/o/site-pics%2Fpartners%2Flogo%20SM.svg?alt=media&token=27725b6d-695e-472c-b463-db9fa739a519"
                              class="shadow-4 mb-3 image"
                              aria-controls="#picker-editor"
                              draggable="false"
                            />
                          </a>
                        </div>
                      </div>
                      <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="square-holder">
                          <a
                            href="https://www.swiss-solidarity.org/ "
                            target="_blank"
                          >
                            <img
                              alt=""
                              src="https://firebasestorage.googleapis.com/v0/b/oka-ventures.appspot.com/o/site-pics%2Fpartners%2Flogo-SS.svg?alt=media&token=6803ad24-01e6-4438-a8b8-aa678b6c85ea"
                              class="shadow-4 mb-3 image"
                              aria-controls="#picker-editor"
                              draggable="false"
                            />
                          </a>
                        </div>
                      </div>
                      <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="square-holder">
                          <a
                            href="https://www.eda.admin.ch/eda/de/home.html"
                            target="_blank"
                          >
                            <img
                              alt=""
                              src="https://firebasestorage.googleapis.com/v0/b/oka-ventures.appspot.com/o/site-pics%2Fpartners%2Flogo_ch_sdc.png?alt=media&token=672c3db9-1b9d-4ac9-b918-22f2e75ac8b2"
                              class="shadow-4 mb-3 image"
                              aria-controls="#picker-editor"
                              draggable="false"
                            />
                          </a>
                        </div>
                      </div>
                      <div class="col-sm-6 col-md-4 col-lg-3 bg-gradient">
                        <div class="square-holder">
                          <a href="https://www.girlmove.org/" target="_blank">
                            <img
                              alt=""
                              src="https://firebasestorage.googleapis.com/v0/b/oka-ventures.appspot.com/o/site-pics%2Fpartners%2Fgirlmove.png?alt=media&token=372a2d57-0700-44c9-b78c-ba7127c691ae"
                              class="shadow-4 mb-3 image"
                              aria-controls="#picker-editor"
                              draggable="false"
                            />
                          </a>
                        </div>
                      </div>
                      <div></div>
                      <div class="col-sm-6 col-md-4 col-lg-3 ">
                        <div class="square-holder">
                          <a
                            href="https://www.essentialtech.ch/"
                            target="_blank"
                          >
                            <img
                              alt=""
                              src="https://firebasestorage.googleapis.com/v0/b/oka-ventures.appspot.com/o/site-pics%2Fpartners%2Fepfel.png?alt=media&token=6bae90a9-265b-4602-a164-93774a310d04"
                              class="shadow-4 mb-3 image"
                              aria-controls="#picker-editor"
                              draggable="false"
                            />
                          </a>
                        </div>
                      </div>
                      <div class="col-sm-6 col-md-4 col-lg-3">
                        <div class="square-holder">
                          <a href="https://www.unilu.ch/" target="_blank">
                            <img
                              alt=""
                              src="https://firebasestorage.googleapis.com/v0/b/oka-ventures.appspot.com/o/site-pics%2Fpartners%2Flucern.png?alt=media&token=c70fe25f-c1e9-41cb-9755-362abaf30826"
                              class="shadow-4 mb-3 image"
                              aria-controls="#picker-editor"
                              draggable="false"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </section>
            <hr class="my-2"/>
          </section>
        </div>

        <section draggable="false" class="container pt-5" data-v-271253ee="">
          <section class=" text-center">
            <h2 class="fw-bold py-5">
              <span>Meet the</span> team
            </h2>
            <div class="row gx-lg-5 py-5">

              <div class="col-lg-4 mb-10 mb-lg-0" style={{marginTop: "120px"}}>
                <div class="card shadow-2-strong h-100">
                  <div
                    class="d-flex justify-content-center"
                    style={{ marginTop: "-75px" }}
                  >
                    {' '}
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/oka-ventures.appspot.com/o/site-pics%2Fsonil-circ.png?alt=media&token=83927f82-fd14-4420-9355-e816d8f3a42d"
                      class="rounded-circle shadow-5-strong imgHW"
                      alt=""
                      
                      aria-controls="#picker-editor"
                    />{' '}
                  </div>
                  <div class="card-body">
                    <h5 class="fw-bold">Sonil Joanguente</h5>
                    <p class="text-muted">CEO</p>
                    <ul class="list-unstyled mb-0">
                      <a href="#!" class="px-1">
                        <i
                          class="fab fa-facebook-f"
                          aria-controls="#picker-editor"
                        ></i>
                      </a>
                      <a href="#!" class="px-1">
                        <i
                          class="fab fa-twitter"
                          aria-controls="#picker-editor"
                        ></i>
                      </a>
                      <a
                        href="https://www.linkedin.com/in/joanguete-sonil-635a48120/"
                        target="_blank"
                        class="px-1"
                      >
                        <i
                          class="fab fa-linkedin-in"
                          aria-controls="#picker-editor"
                        ></i>
                      </a>
                    </ul>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 mb-10 mb-lg-0" style={{marginTop: "120px"}}>
                <div class="card shadow-2-strong h-100">
                  <div class="d-flex justify-content-center">
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/oka-ventures.appspot.com/o/site-pics%2FMH.jpeg?alt=media&token=9600ab24-1c3a-4f28-923a-cb37b393abe7"
                      class="rounded-circle shadow-5-strong imgHW"
                      alt=""
                      style={{ marginTop: "-75px" }}
                      aria-controls="#picker-editor"
                    />
                  </div>
                  <div class="card-body">
                    <h5 class="fw-bold">
                      Michael A. Hobbins, PhD Epidemiology
                    </h5>
                    <p class="text-muted">
                      Founder and Technical advising Content, Implementation and
                      Quality
                    </p>
                    <ul class="list-unstyled mb-0">
                      <a href="#!" class="px-1">
                        <i
                          class="fab fa-facebook-f"
                          aria-controls="#picker-editor"
                        ></i>
                      </a>
                      <a href="#!" class="px-1">
                        <i
                          class="fab fa-twitter"
                          aria-controls="#picker-editor"
                        ></i>
                      </a>
                      <a
                        href="https://www.linkedin.com/in/michael-a-hobbins-b8942424/"
                        target="_blank"
                        class="px-1"
                      >
                        <i
                          class="fab fa-linkedin-in"
                          aria-controls="#picker-editor"
                        ></i>
                      </a>
                    </ul>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 mb-10 mb-lg-0" style={{marginTop: "120px"}}>
                <div class="card shadow-2-strong h-100">
                  <div
                    class="d-flex justify-content-center"
                    style={{ marginTop: "-75px" }}
                  >
                    {' '}
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/oka-ventures.appspot.com/o/site-pics%2Fsash.jpeg?alt=media&token=9a043bcd-afaa-4dc3-aa3a-71e47147e27f"
                      class="rounded-circle shadow-5-strong imgHW"
                      alt=""
                      aria-controls="#picker-editor"
                    />{' '}
                  </div>
                  <div class="card-body">
                    <h5 class="fw-bold">
                      Sashidhar Jonnalagedda, MSc Electrical Engeneering
                    </h5>
                    <p class="text-muted">
                      Founder and Technical Advising Engeneering and Business
                      Development
                    </p>
                    <ul class="list-unstyled mb-0">
                      <a href="#!" class="px-1">
                        <i
                          class="fab fa-facebook-f"
                          aria-controls="#picker-editor"
                        ></i>
                      </a>
                      <a href="#!" class="px-1">
                        <i
                          class="fab fa-twitter"
                          aria-controls="#picker-editor"
                        ></i>
                      </a>
                      <a
                        href="https://www.linkedin.com/in/ojihsas/"
                        target="_blank"
                        class="px-1"
                      >
                        <i
                          class="fab fa-linkedin-in"
                          aria-controls="#picker-editor"
                        ></i>
                      </a>
                    </ul>
                  </div>
                </div>
              </div>

              

              <div class="col-lg-4 mb-10 mb-lg-0" style={{marginTop: "120px"}}>
                <div class="card shadow-2-strong h-100">
                  <div
                    class="d-flex justify-content-center"
                    style={{ marginTop: "-75px" }}
                  >
                    {' '}
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/oka-ventures.appspot.com/o/site-pics%2F2022_sm_barbara_kruspan_1.jpeg?alt=media&token=26af8d86-2049-4f6f-aee3-26985899e4db"
                      class="rounded-circle shadow-5-strong imgHW"
                      alt=""
                      
                      aria-controls="#picker-editor"
                    />{' '}
                  </div>
                  <div class="card-body">
                    <h5 class="fw-bold">Barbara Kruspan</h5>
                    <p class="text-muted text-">
                      Technical Advising Policy Manager
                    </p>
                    <ul class="list-unstyled mb-0">
                      <a href="#!" class="px-1">
                        <i
                          class="fab fa-facebook-f"
                          aria-controls="#picker-editor"
                        ></i>
                      </a>
                      <a href="#!" class="px-1">
                        <i
                          class="fab fa-twitter"
                          aria-controls="#picker-editor"
                        ></i>
                      </a>
                      <a
                        href="https://www.linkedin.com/in/barbarakruspan/"
                        target="_blank"
                        class="px-1"
                      >
                        <i
                          class="fab fa-linkedin-in"
                          aria-controls="#picker-editor"
                        ></i>
                      </a>
                    </ul>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 mb-10 mb-lg-0" style={{marginTop: "120px"}}>
                <div class="card shadow-2-strong h-100">
                  <div
                    class="d-flex justify-content-center"
                    style={{ marginTop: "-75px" }}
                  >
                    {' '}
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/oka-ventures.appspot.com/o/site-pics%2FPaulo.png?alt=media&token=fee6d9fe-f50d-49c2-92f4-14f6d82cbaf3"
                      class="rounded-circle shadow-5-strong imgHW"
                      alt=""
                      
                      aria-controls="#picker-editor"
                    />{' '}
                  </div>
                  <div class="card-body">
                    <h5 class="fw-bold">Paulo Monteiro</h5>
                    <p class="text-muted">community Relations</p>
                    <ul class="list-unstyled mb-0">
                      <a href="#!" class="px-1">
                        <i
                          class="fab fa-facebook-f"
                          aria-controls="#picker-editor"
                        ></i>
                      </a>
                      <a href="#!" class="px-1">
                        <i
                          class="fab fa-twitter"
                          aria-controls="#picker-editor"
                        ></i>
                      </a>
                      <a href="#!" target="_blank" class="px-1">
                        <i
                          class="fab fa-linkedin-in"
                          aria-controls="#picker-editor"
                        ></i>
                      </a>
                    </ul>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 mb-10 mb-lg-0" style={{marginTop: "120px"}}>
                <div class="card shadow-2-strong h-100">
                  <div
                    class="d-flex justify-content-center"
                    style={{ marginTop: "-75px" }}
                  >
                    {' '}
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/oka-ventures.appspot.com/o/site-pics%2Ffaruk.png?alt=media&token=0ca46864-e1de-4d60-8335-442f67914862"
                      class="rounded-circle shadow-5-strong imgHW"
                      alt=""
                      
                      aria-controls="#picker-editor"
                    />{' '}
                  </div>
                  <div class="card-body">
                    <h5 class="fw-bold">Faruk Mamudo</h5>
                    <p class="text-muted">Financial and Logistic Support</p>
                    <ul class="list-unstyled mb-0">
                      <a href="#!" class="px-1">
                        <i
                          class="fab fa-facebook-f"
                          aria-controls="#picker-editor"
                        ></i>
                      </a>
                      <a href="#!" class="px-1">
                        <i
                          class="fab fa-twitter"
                          aria-controls="#picker-editor"
                        ></i>
                      </a>
                      <a href="#!" target="_blank" class="px-1">
                        <i
                          class="fab fa-linkedin-in"
                          aria-controls="#picker-editor"
                        ></i>
                      </a>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>
      </Container>

      <Footer />
    </>
  )
}

export default WhatWeDo
