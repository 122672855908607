import React from 'react'
import { Container, Row, Col, Card, CardGroup } from 'react-bootstrap'
import Carousel from 'react-bootstrap/Carousel'
import './commons/header.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  solid,
  regular,
  brands,
  icon,
} from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used
import Footer from './commons/Footer'

const Home = () => {
  return (
    <div>
      <Container>
        <Carousel>
          <Carousel.Item>
            <img
              className="d-block w-100 "
              src="https://firebasestorage.googleapis.com/v0/b/oka-ventures.appspot.com/o/site-pics%2Fbg1.JPG?alt=media&token=a981c7a2-376c-4b4d-95b6-21984b6dd7e6"
              alt="First slide"
            />
            <Carousel.Caption>
              <h2>
                These tuktuks are taxis, that bring women in labour to the next
                health facility for free and on time. This tuktuk saves lives
              </h2>
              <p></p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src="https://firebasestorage.googleapis.com/v0/b/oka-ventures.appspot.com/o/site-pics%2Fbg3.jpeg?alt=media&token=7eea7242-dc90-4098-a7a5-3ad8349e8d45"
              alt="Second slide"
            />

            <Carousel.Caption>
              <h3>
                These solar TVs bring free information and education as well as
                paid entertainment to secluded communities
              </h3>
              <p></p>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </Container>

      <main>
        <Container>
          <Row className="px-4 my-5 text-center fs-1 ">
            <h1>
              Supporting Micro-entrepreneurs <br /> set up their businesses
            </h1>
          </Row>

          <Row className="px-4 my-1 ">
            <Col sm={12}>
              <div className="container-md">
                <p class="text-sm-center text-break fs-4 text-wrap">
                  Oka Ventures supports micro-entrepreneurs in rural communities
                  by renting out specific equipment and giving individual
                  advice. The micro entrepreneurs set up their businesses, earn
                  a living and commit to support their own communities. It is
                  locally established, locally owned, demonstrated resilience
                  during various crisis and is striving to grow. Oka Ventures
                  contibutes to 11 of the 17 sustainable development goals
                </p>
              </div>
            </Col>
          </Row>

          <Row className="px-4 my-5 text-center">
            <Col sm={12}>
              <h2 className="fs-1">Growing Oka Ventures means:</h2>
              <p>Pulling more people out of poverty</p>
              <p>
                Increasing the access to essential services for their community
              </p>
              <p>Ighting inequity</p>
              <p>
                Setting up businesses build on solid, local, cultural values
              </p>
            </Col>

            {/* <dl class="row">
              <dt class="col-sm-3 fs-5">Growing Oka Ventures means:</dt>
              <dd class="col-sm-9">
                <p class="text-body ">Pulling more people out of poverty</p>
                <p>
                  Increasing the access to essential services for their
                  community
                </p>
                <p>Ighting inequity</p>
                <p>
                  Setting up businesses build on solid, local, cultural values
                </p>
              </dd>
            </dl> */}
          </Row>
        </Container>

        <div class="">
          <Row className="px-4 my-5 text-center fs-1 ">
            <h1>Our ventures</h1>
          </Row>
          <Container>
            <div class="card-deck my-5 bg-light">
              <CardGroup>
                <Card>
                  <Card.Img
                    variant="top"
                    src="https://firebasestorage.googleapis.com/v0/b/oka-ventures.appspot.com/o/site-pics%2FOkaTaxiImpact.jpg?alt=media&token=819ff61c-5c55-4005-9179-4868e0464035"
                  />
                  <Card.Body>
                    <Card.Title className="text-center">Oka Taxi</Card.Title>
                    <Card.Text>
                      Oka Taxis are taxis that bring women in labour to the next
                      health facility for free and on time, while making a
                      profiut by offering taxi services to paying clients.
                    </Card.Text>
                  </Card.Body>
                </Card>

                <Card>
                  <Card.Img
                    variant="top"
                    src="https://firebasestorage.googleapis.com/v0/b/oka-ventures.appspot.com/o/site-pics%2Fokafreeze.jpeg?alt=media&token=ee9f9ad6-4dd2-4dbd-bdc9-eacecb1b78f2"
                  />
                  <Card.Body>
                    <Card.Title className="text-center">Oka Freeze</Card.Title>
                    <Card.Text>
                      Oka Freeze are solar freezers that provide community
                      members the opportunity to diversify their diet by
                      offering cold sensitive foods and drinks in a off-grid
                      environment.
                    </Card.Text>
                  </Card.Body>
                </Card>
                <Card>
                  <Card.Img
                    variant="top"
                    src="https://firebasestorage.googleapis.com/v0/b/oka-ventures.appspot.com/o/site-pics%2Fokatv.jpeg?alt=media&token=8a3b0cd5-bef8-494f-b4e7-d50b190960eb"
                  />
                  <Card.Body>
                    <Card.Title className="text-center">Oka TV</Card.Title>
                    <Card.Text>
                      Oka TVs are solar TVs that enable remote and secluded
                      communities to access free information and education as
                      well as paid entertainment.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </CardGroup>
            </div>

            <section class="container pt-5">
              <h1 className="text-center">We are pround</h1>
              <div class=" py-5 my-5  service-1">
                <div class="container">
                  {/* <!-- Row  --> */}
                  <div class="row">
                    {/* <!-- Column --> */}
                    <div class="col-md-4 wrap-service1-box">
                      <div class="card border-0 card-shadow mb-4">
                        <div class="card-body text-center">
                          <div class="my-3">
                            <span>
                              {' '}
                              <FontAwesomeIcon
                                size="5x"
                                color="#333"
                                icon={solid('users')}
                              />
                              {/* <i className="fa fa-5x fa-users"></i>{' '} */}
                            </span>
                          </div>
                          <h3 class="fw-bold text-warning mb-3">25+</h3>
                          <p class="text-muted">
                            Of the 25+ micro-entrepreneurs we supported to be
                            pulled out of poverty and are now socially
                            responsible business holders
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* <!-- Column --> */}
                    <div class="col-md-4 wrap-service1-box">
                      <div class="card border-0 card-shadow mb-4">
                        <div class="card-body text-center">
                          <div class="my-3">
                            <span>
                              {' '}
                              <i className="fa fa-5x fa-ambulance "></i>{' '}
                            </span>
                          </div>
                          <h3 class="fw-bold text-warning mb-3">4</h3>
                          <p class="text-muted">
                            Of enabling the free and on-time transport of - on
                            average - 4 women in labour per month and tuktuk to
                            the next health facility for safe birth.
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* <!-- Column --> */}
                    <div class="col-md-4 wrap-service1-box">
                      <div class="card border-0 card-shadow mb-4">
                        <div class="card-body text-center">
                          <div class="my-3">
                            <span>
                              {' '}
                              <i className="fa fa-5x fa-cogs"></i>{' '}
                            </span>
                          </div>
                          <h3 class="fw-bold text-warning mb-3">20+</h3>
                          <p class="text-muted">
                            Of providing access to essential services to 20+
                            communities that increases their livelihood.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <hr class="my-5" />

            <section class="mb-10 background-radial-gradient">
              <div class="container px-4 py-5 px-md-5 text-center text-lg-start">
                <div class="row gx-lg-5 align-items-center my-5 my-lg-0">
                  <div class="col-lg-6 mb-5 mb-lg-0">
                    <h3 class="mb-5 display-3 fw-bold ls-tight">
                      {' '}
                      <span>Get a fell</span>
                      <br /> Watch the Video{' '}
                    </h3>
                  </div>
                  <div class="col-lg-6">
                    <div class="ratio ratio-16x9">
                      <iframe
                        width="560"
                        height="315"
                        src="https://www.youtube.com/embed/xXM8C6jxxI8"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </Container>
        </div>

        <div data-draggable="true" style={{position: "relative;"}}>
		
			<section draggable="false" class="container pt-5" data-v-271253ee="">
			  <section class="mb-10">
				<h2 class="fw-bold mb-5 text-center">Where we work</h2>
				<div class="row gx-lg-5 align-items-center to-blue-400 mb-5 flex-lg-row-reverse">
				  <div class="col-md-6 mb-4 mb-md-0">
					<div class="map hover-overlay ripple shadow-1-strong rounded-4 mb-4" data-mdb-ripple-color="light">
						<svg version="1.1" xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 800 600"
						>
					  
					   <g id="Mozambique">
						
							<path class="st0" d="M293.796,569.294l0.109-8.969l-0.321-2.847l-1.468-2.967l-0.233-0.972l-0.387-3.577l0.151-1.01l1.028-2.739
l0.112-0.923l-0.214-1.199l-0.368-1.281l-0.164-1.244l0.408-1.087l-2.069-0.533l-0.893-0.482l-0.809-0.772l-1.221-3.978
l-0.129-1.035l0.19-0.956l1.32-3.463l0.244-0.417l0.398-0.385l0.302-0.185l0.212-0.21l0.129-0.46l-0.003-0.748l-0.287-2.162
l-0.182-0.719l-0.324-0.712l-0.116-1.075l0.038-1.131l0.125-0.872l0.53-1.682l-0.046-7.466l-0.029-5.458l-0.021-4.092l-0.032-5.768
l-0.026-4.764l0.254-2.348l-0.167-2.64l-0.784-2.62l-1.089-2.373l0.491-0.308l3.887-1.911l0.231-0.014l0.236,0.016l0.284,0.051
l0.744,0.233l1.813,0.282l0.39,0.234l0.135,0.1l0.565,0.196l1.002,0.174l0.265,0.07l0.758,0.395l3.646,0.529l0.491,0.197
l0.279,0.185l0.251,0.219l1.678,1.893l0.173,0.122l0.931,0.365l0.867,0.55l0.469,0.205l0.308,0.074l0.28,0.021l0.217-0.025
l0.197-0.049l0.749-0.273l0.626-0.132l0.251,0.014l0.193,0.058l0.149,0.079l0.137,0.101l0.376,0.208l0.781,0.261l0.178,0.092
l0.188,0.164l0.106,0.162l0.063,0.169l0.019,0.15l-0.029,0.52l0.149,3.38l0.055,0.194l0.072,0.169l0.087,0.157l1.19,1.418
l1.919,2.953l0.156,0.332l0.116,0.376l0.145,0.847l0.145,0.225l0.236,0.248l0.482,0.401l0.419,0.271l1.587,0.658l0.267,0.249
l0.051,0.41l-0.516,1.886l-0.048,0.515l0.024,0.521l0.299,0.574l0.092,0.333l0.014,0.234l-0.087,0.142l-0.106,0.135l-0.106,0.115
l-0.082,0.14l-0.043,0.147l0.207,3.609l0.077,0.354l0.149,0.138l0.18,0.064l0.689,0.067l0.308,0.08l0.458,0.209l0.222,0.176
l0.125,0.199l0.029,0.197l-0.051,0.363l-0.035,0.149l0.019,0.135l0.048,0.151l0.05,0.314l-0.014,0.6l0.164,1.047l-0.024,0.192
l-0.13,0.445l-0.019,0.117l-0.006,0.16l0.05,0.319l0.106,0.302l0.13,0.044l2.208,0.249l0.525,0.176l0.44,0.238l0.217,0.176
l0.149,0.176l0.265,0.467l0.145,0.346l0.053,0.19l0.087,0.639l-0.005,0.21l-0.125,0.78l-0.14,0.549l-0.024,0.192l0.054,1.53
l-8.059,5.67l-0.87,0.993l-1.629,2.322l-0.708,1.437l-0.567,2.212l-0.435,0.741l0.228,1.714l-0.147,0.306l0.025-0.199l-0.061-0.242
l-0.126-0.315H312.9l-0.217,0.284l-0.288,0.194l-0.248,0.219l-0.111,0.362l-0.03,0.492l-0.083,0.467l-0.129,0.424l-0.182,0.388
l-0.878,0.852l-0.888-0.802l-2.408-0.744l-0.803,1.834l-0.319,1.073l-0.511,0.166l-0.465,0.368l-0.26,0.661l0.124,0.644
l0.329,0.377l0.329-0.16l-0.03-0.169l0.03-1.123l0.212-0.124l0.46,0.079l0.473,0.155l0.258,0.127l0.154,0.264l0.228,0.574
l0.147,0.217l0.303,0.189l0.668,0.259l0.321,0.279l0.22,0.38l0.278,0.788l0.223,0.383l0.268,0.253l0.24,0.172l0.169,0.2
l0.182,1.059l0.562,1.479l0.192,0.744l0.536-0.51l0.789,0.169l0.764,0.457l0.46,0.358l0.67,0.826l0.402,0.79l0.445,0.694
l0.602,0.53l0.354,0.169l0.321-0.141l0.364-0.203l0.463-0.042l-0.288-1.1l-0.04-0.914l0.167-0.922l0.354-1.108l0.286-1.598
l0.258-0.417l0.379-0.284l1.217-0.538l0.02,0.927l-0.797,5.556l-0.349,9.652l-0.422,2.63l-0.283,0.787l-0.076,0.408l0.192,1.427
l-0.192,2.753l-0.068,0.348l-0.357,0.961l0.056,0.351l0.271,0.507l0.099,0.332l-4.806,0.144l-4.362,0.13l-4.015,0.119L301.204,570
l-1.752-0.288l-2.263-0.371l-0.9-0.036l-1.582,0.187L293.796,569.294z M318.439,542.14l-0.354-1.016l0.713-0.934l1.121-0.72
l0.878-0.4l-0.02,0.385l-0.213,1.013l-0.329,0.988l-0.101,0.211l-0.291,0.307l-0.078,0.034l-0.101-0.141l-0.354-0.2l-0.121-0.16
							l-0.015-0.236l-0.111-0.152l-0.41,0.076l0.008,0.144L318.439,542.14z" ></path>
						   
						   {/* <a xlink:title="Cabo Delgado" xlink:href="" > */}
							   
						   <path class="st0 map-active" d="M552.48,30.183L553.011,30l0.481,0.113l0.187,0.319l-0.329,0.417l0.587,0.134l0.832-0.707l0.513,0.34
l0.157,0.664l-0.329,0.527l-0.463,0.417l-0.235,0.353l0.235,0.502l0.564,0.208l0.647,0.126l0.481,0.244l0.22,0.479l0.073,1.078
l0.228,0.504l0.445,0.255l1.219,0.157l0.478,0.237l0.255,0.525l-0.453,0.139l-1.191-0.116l-1.002,0.286l-2.625,2.07l0.212,1.102
l0.243-0.17l0.174-0.196l0.124-0.221l0.096-0.278l0.599,0.512l2.178,1.097l0.567,0.646l-0.582,0.603l-1.705,0.814l-0.564,0.515
l-0.63,0.814l-0.379,0.889l0.326,1.172l-0.255,1.093l0.253,0.314l0.354-0.088l0.407-0.789l0.296-0.204l0.794,0.343l-0.01,0.902
l-0.427,1.01l-0.47,0.66l-0.994,1.059l-0.182,0.348l-0.02,0.41l0.233,0.792l0.02,0.315l-0.385,0.549l-2.069,1.524l-0.233,0.413
l-0.455,1.104l-0.255,0.32l-0.397,0.139h-0.744l-0.364,0.059l0.319,0.477l0.268,0.606l0.243,0.235l0.233-0.65l0.47,0.276
l0.488,0.464l0.425,0.519l0.291,0.454l0.9-0.222l0.172,1.001l-0.195,1.066l-0.22,1.226l-0.326,0.986l-0.25,0.434l-0.369,0.429
l-0.132,0.503l0.364,0.493l0.478,0.449l0.235,0.387l-0.187,0.356l-0.701,0.801l-0.058,0.271l0.334,0.462l0.101,0.372l0.086,1.028
l0.144,0.625l0.574,0.553l0.101,0.171l0.04,0.201l0.056,1.545l0.177,0.545l0.395,0.496l0.268-0.153l0.167-0.031l0.435,0.184
l-0.845,0.861l-0.212,0.923l0.212,0.949l0.42,0.936l0.943,1.344v0.292l-0.468,0.499l-0.197,0.331l-0.086,1.024l-0.369,1.529
l-0.056,0.448l0.121,0.564l0.572,0.875l0.177,0.512l-0.066,0.528l-0.235,0.652l-0.357,0.559l-0.425,0.238l-0.445-0.137l-0.44-0.186
l-0.314,0.085l-0.076,0.668l0.766-0.176l0.683,0.513l0.493,0.839l0.202,0.803l-0.096,0.44l-0.197,0.386l-0.131,0.401l0.094,0.507
l0.273,0.259l0.427,0.072l0.901-0.114l-0.091,0.332l-0.182,0.267l-0.253,0.186l-0.713,0.184l-0.202,0.29l-0.253,0.676l-0.814,1.158
l-0.066,0.534l0.435,0.503l0.329,0.145l0.377,0.062l1.305,0.062l0.159,0.15l-0.03,2.182l0.116,0.461l0.278,0.202l0.354,0.091
l0.9,0.464l0.172,0.264l-0.218,0.394l-0.309,0.047l-0.531-0.609h-0.339l-0.207,0.311l0.02,0.404l0.293,1.403l0.258,0.01
l0.341-0.182l0.357,0.029l0.223,0.329l0.263,0.83l0.152,0.166l0.374,0.109l0.167,0.27v0.35l-0.116,0.358l-0.501,0.514l-0.668,0.148
l-0.668-0.179l-0.501-0.482l-0.025,0.257l0.056,0.319l0.096,0.249l0.086,0.047l-0.455,0.288l-0.873-0.078l-0.154,0.239l0.192,0.267
l0.384,0.252l0.276,0.371l-0.121,0.623l-0.349,0.848l-0.19,0.257l-0.273,0.265l-0.083,0.029l-0.073-0.122l-0.207-0.171l0.03-0.267
l-0.106-0.213l-0.349-0.174l-0.273-0.029l-0.192,0.052l-0.893,0.475l-0.635,0.451l-0.526,0.527l-0.22,0.485l0.053,0.605
l0.159,0.345l1.571,1.441l0.471,0.156l0.486-0.171l0.415-0.561l-0.164-0.452l-0.336-0.441l-0.126-0.509l0.248-0.223l0.476-0.018
l0.516,0.132l0.362,0.218l0.448,0.125l0.526-0.145l0.44,0.005l0.187,0.566l-0.174,1.033l-0.744,1.849l-0.144,1.054l0.076,0.569
l0.288,1.075l0.061,0.558l-0.212,2.403l-0.174,0.624l-0.397,0.372l-0.435,0.314l-0.263,0.444l0.081,0.382l0.41-0.159l0.779-0.655
v2.615l0.144-0.057l0.349-0.083l0.144-0.057l-0.121,0.728l-0.941,1.019l-0.233,0.533l-0.063,1.371l-0.185,0.666l-0.364,0.479v0.003
l-0.681,0.319l-0.848-0.017l-0.726-0.358l-0.307-0.699l-0.212-0.707l-0.54-0.281l-1.466-0.005l-1.179,0.229l-2.573,0.795
l-1.264-0.131l-0.429-0.266l-0.299-0.292l-0.39-0.238l-1.144-0.182l-0.173-0.139l-0.157-0.026l-0.398,0.249l-0.758,0.846
l-0.302,0.246l-1.307,0.431l-0.577,0.277l-0.422,0.856l-0.429,0.344l-0.891,0.492l-0.835,0.79l-1.755,2.172l-1.017,0.552h-0.382
l-0.988-0.218h-1.293l-0.66,0.192l-1.789,1.337l-0.596,0.218l-0.581,0.134l-1.274,0.084l-0.31,0.084l-0.239,0.197l-0.206,0.218
l-0.209,0.157l-0.318,0.091l-0.975,0.147l-0.482,0.19l-0.559,0.296l-0.464,0.347l-0.193,0.357l-0.231,0.584l-0.559,0.554
l-0.697,0.452l-0.634,0.28l-1.177,0.218l-0.284,0.101l-0.312,0.452l-0.252,0.103l-0.639,0.124l-0.695,0.301l-1.097,0.688
l-1.084,0.908l-0.734,0.316l-1.142-0.477l-0.801-0.048l-1.37,0.154l-0.681,0.271l-0.605,0.452l-0.437,0.614l-0.167,0.76
l-0.303,0.159l-1.857,0.409l-1.331,0.949l-0.983,0.331l-1.717,1.325l-0.585,0.189l-2.088,0.268l-0.458,0.139l-1.038,0.604
l-0.732,0.113l-3.905,0.109l-1.208-0.328l-1.172-0.47l-0.949-0.149l-0.951,0.18l-1.862-1.623l-0.438-0.575l-0.087-0.343
l-0.14-0.291l-1.842-2.738l-0.198-0.667l-0.151-0.215l-0.26-0.288l-0.101-0.213l-0.034-0.21l0.01-0.189l-0.024-0.175l-0.053-0.162
l-0.4-0.878l-0.14-0.225l-0.173-0.195l-0.448-0.42l-0.149-0.243l-0.975-2.477l-0.043-0.193l-0.135-0.43l-0.043-0.203l-0.014-0.203
l0.043-0.4l-0.019-0.296l-0.067-0.357l-0.198-0.618l-0.154-0.362l-0.44-0.737l-0.462-0.623l-0.106-0.223l-0.048-0.221l-0.005-0.2
l0.207-1.191l0.01-0.243l-0.039-0.352l-0.193-0.355l-0.193-0.514l-0.202-0.763l-0.135-0.223l-0.17-0.119l-0.167,0.002l-0.161,0.048
l-0.149,0.066l-0.14,0.078l-0.13,0.089l-0.169,0.04l-0.188-0.013l-0.284-0.19l-0.125-0.182l-0.082-0.243l-0.262-1.366v-0.188
l0.035-0.172l0.042-0.14l0.055-0.096l0.063-0.099l0.511-0.57l0.636-0.476l0.58-0.52l0.4-0.254l0.231-0.203l0.544-0.563l0.092-0.13
l0.072-0.153l0.096-0.36l0.064-0.168l0.087-0.139l0.096-0.122l0.333-0.327l0.091-0.135l0.183-0.498l0.092-0.134l0.202-0.243
l0.063-0.152l0.024-0.188l-0.024-0.365l0.014-0.175l0.063-0.147l0.173-0.257l0.029-0.158l-0.005-0.361l0.029-0.163l0.063-0.147
l0.308-0.35l0.074-0.153l0.022-0.267l-0.042-0.879l-0.093-0.251l-0.13-0.114l-0.361-0.017l-0.371,0.045l-0.149,0.051l-0.145,0.069
l-1.115,0.741l-0.13,0.038l-0.116-0.02l-0.333-0.102l-0.385-0.076l-0.349-0.112l-0.149-0.074l-0.14-0.084l-0.222-0.221
l-0.188-0.247l-0.111-0.11l-0.125-0.097l-0.149-0.079l-0.681-0.228l-1.596-0.305l-0.198-0.091l-0.183-0.203l-0.019-0.168
l0.043-0.16l0.686-0.87l0.164-0.297l0.053-0.201l0.034-0.219l0.014-0.597l0.077-0.26l0.043-0.257l0.019-0.351l-0.063-1.057
l0.01-0.305l0.091-0.369l0.024-0.219l0.01-0.251l-0.039-0.349v-0.257l0.111-0.789l-0.048-0.621l0.034-0.318l0.072-0.222
l0.207-0.235l0.077-0.147l0.058-0.17l0.034-0.193l0.019-0.203l-0.082-0.95l0.019-0.188l0.043-0.173l0.387-0.739l0.032-0.212
l-0.061-1.462l-0.088-0.273l-0.154-0.305l-0.067-0.212l-0.019-0.221l0.32-1.519l0.106-0.341l0.096-0.548l0.019-0.53l-0.029-0.291
l-0.055-0.222l-0.188-0.326l-0.275-0.648l-0.005-0.237l0.063-0.17l0.13-0.089l0.435-0.242l0.511-0.372l0.222-0.222l0.188-0.253
l0.164-0.283l0.193-0.248l0.879-0.88l0.573-0.758l0.347-0.306l0.546-0.314l0.145-0.179l0.125-0.284l0.246-1.965l0.135-0.552
l0.463-1.113l0.149-0.565l0.072-0.205l0.141-0.095l0.294-0.143L483.812,85l0.405-0.409l0.352-0.473l0.106-0.104l0.116-0.092
l0.13-0.079l0.604-0.255l0.395-0.233l1.129-0.943l0.101-0.304l0.077-0.48l0.082-1.092l0.087-0.475l0.106-0.3l0.125-0.069
l0.636-0.248l0.135-0.105l0.122-0.14l0.28-0.721l0.13-0.744l0.068-0.162l0.096-0.108l0.381-0.245l0.41-0.429l0.072-0.128
l0.053-0.146l0.135-0.867l0.053-0.161l0.074-0.135l0.096-0.327l0.207-1.594l0.01-0.501l-0.024-0.341l-0.145-0.272l-0.344-0.507
l-0.294-0.333l-0.284-0.548l-0.082-0.123l-0.159-0.333l-0.183-0.728l-0.014-0.284l0.188-2.249l0.222-0.913l0.082-0.662l0.072-0.33
l0.082-0.236l0.159-0.241l0.082-0.177l0.067-0.239l0.111-0.669l0.373-0.908l0.169-0.872l0.053-0.152l0.063-0.144l0.077-0.131
l1.171-1.585l0.016-0.057l0.774-0.963l3.061-2.087l0.7-0.888l0.498-0.426l0.925-0.252l0.63-0.295l0.337-0.065l0.373,0.039
l0.671,0.16l0.344,0.013l0.569-0.301l1.237-1.186l0.411-0.262l0.649-0.164l0.546-0.383l0.832-0.73l1.05-0.242l4.064,0.003
l1.076-0.213l0.957-0.435l0.732,0.147l1.266,0.694l0.655,0.242l0.826,0.01l0.78-0.236l0.726-0.4l6.094-4.907l0.492-0.272
l0.533-0.177l2.396-0.18l5.214-1.148l0.787-0.334l3.113-2.156l3.206-1.377l0.591-0.311l3.887-3.054l0.867-0.324l0.363-0.356
							l0.585-0.768l1.837-1.83l1.176-0.882l2.014-0.706l1.115-0.804l0.989-0.997L552.48,30.183z" />
              {/* </a> */}


						   <path class="st0" d="M479.642,54.917l0.243,0.005v-0.049l0.096-0.092l0.157-0.075l0.19-0.003l0.055,0.079l0.164,0.292l0.099,0.062
l0.302,0.102l0.61,0.449l0.369,0.102l0.357-0.062l0.687-0.288l0.308-0.085l1.436,0.226l1.115,0.776l1.715,1.812l1.102,0.567
l2.464,0.623l0.911,0.534l0.018,0.01l-0.016,0.057l-1.171,1.585l-0.077,0.131l-0.063,0.144l-0.053,0.152l-0.169,0.872l-0.373,0.908
l-0.111,0.669l-0.067,0.239l-0.082,0.177l-0.159,0.241l-0.082,0.236l-0.072,0.33l-0.082,0.662l-0.222,0.913l-0.188,2.249
l0.014,0.284l0.183,0.728l0.159,0.333l0.082,0.123l0.284,0.548l0.294,0.333l0.344,0.507l0.145,0.272l0.024,0.341l-0.01,0.501
l-0.207,1.594l-0.096,0.327l-0.074,0.135l-0.053,0.161l-0.135,0.867l-0.053,0.146l-0.072,0.128l-0.41,0.429l-0.381,0.245
l-0.096,0.108l-0.068,0.162l-0.13,0.744l-0.28,0.721l-0.122,0.14l-0.135,0.105l-0.636,0.248l-0.125,0.069l-0.106,0.3l-0.087,0.475
l-0.082,1.092l-0.077,0.48l-0.101,0.304l-1.129,0.943l-0.395,0.233l-0.604,0.255l-0.13,0.079l-0.116,0.092l-0.106,0.104
l-0.352,0.473L483.812,85l-0.125,0.092l-0.294,0.143l-0.141,0.095l-0.072,0.205l-0.149,0.565l-0.463,1.113l-0.135,0.552
l-0.246,1.965l-0.125,0.284l-0.145,0.179l-0.546,0.314l-0.347,0.306l-0.573,0.758l-0.879,0.88l-0.193,0.248l-0.164,0.283
l-0.188,0.253l-0.222,0.222l-0.511,0.372l-0.435,0.242l-0.13,0.089l-0.063,0.17l0.005,0.237l0.275,0.648l0.188,0.326l0.055,0.222
l0.029,0.291l-0.019,0.53l-0.096,0.548l-0.106,0.341l-0.32,1.519l0.019,0.221l0.067,0.212l0.154,0.305l0.088,0.273l0.061,1.462
l-0.032,0.212l-0.387,0.739l-0.043,0.173l-0.019,0.188l0.082,0.95l-0.019,0.203l-0.034,0.193l-0.058,0.17l-0.077,0.147
l-0.207,0.235l-0.072,0.222l-0.034,0.318l0.048,0.621l-0.111,0.789v0.257l0.039,0.349l-0.01,0.251l-0.024,0.219l-0.091,0.369
l-0.01,0.305l0.063,1.057l-0.019,0.351l-0.043,0.257l-0.077,0.26l-0.014,0.597l-0.034,0.219l-0.053,0.201l-0.164,0.297l-0.686,0.87
l-0.043,0.16l0.019,0.168l0.183,0.203l0.198,0.091l1.596,0.305l0.681,0.228l0.149,0.079l0.125,0.097l0.111,0.11l0.188,0.247
l0.222,0.221l0.14,0.084l0.149,0.074l0.349,0.112l0.385,0.076l0.333,0.102l0.116,0.02l0.13-0.038l1.115-0.741l0.145-0.069
l0.149-0.051l0.371-0.045l0.361,0.017l0.13,0.114l0.093,0.251l0.042,0.879l-0.022,0.267l-0.074,0.153l-0.308,0.35l-0.063,0.147
l-0.029,0.163l0.005,0.361l-0.029,0.158l-0.173,0.257l-0.063,0.147l-0.014,0.175l0.024,0.365l-0.024,0.188l-0.063,0.152
l-0.202,0.243l-0.092,0.134l-0.183,0.498l-0.091,0.135l-0.333,0.327l-0.096,0.122l-0.087,0.139l-0.064,0.168l-0.096,0.36
l-0.072,0.153l-0.092,0.13l-0.544,0.563l-0.231,0.203l-0.4,0.254l-0.58,0.52l-0.636,0.476l-0.511,0.57l-0.063,0.099l-0.055,0.096
l-0.042,0.14l-0.035,0.172v0.188l0.262,1.366l0.082,0.243l0.125,0.182l0.284,0.19l0.188,0.013l0.169-0.04l0.13-0.089l0.14-0.078
l0.149-0.066l0.161-0.048l0.167-0.002l0.17,0.119l0.135,0.223l0.202,0.763l0.193,0.514l0.193,0.355l0.039,0.352l-0.01,0.243
l-0.207,1.191l0.005,0.2l0.048,0.221l0.106,0.223l0.462,0.623l0.44,0.737l0.154,0.362l0.198,0.618l0.067,0.357l0.019,0.296
l-0.043,0.4l0.014,0.203l0.043,0.203l0.135,0.43l0.043,0.193l0.975,2.477l0.149,0.243l0.448,0.42l0.173,0.195l0.14,0.225l0.4,0.878
l0.053,0.162l0.024,0.175l-0.01,0.189l0.034,0.21l0.101,0.213l0.26,0.288l0.151,0.215l0.198,0.667l1.842,2.738l0.14,0.291
l0.087,0.343l0.438,0.575l1.862,1.623l-0.959,0.439l-1.216,0.354l-2.694,0.313l-1.222,0.644l-1.071,1.11l-0.63,0.467l-5.233,0.993
l-0.3,0.197l-0.198,0.323l-0.475,0.403l-0.567,0.342l-0.477,0.128l-1.129-0.563l-0.646-1.008l-0.72-0.825l-1.346-0.013
l-0.554,0.358l-0.808,0.976l-0.442,0.199l-2.44-0.219l-0.615,0.058l-0.678,0.258l-0.376,0.462l0.289,0.665l0.008,0.585
l-0.514,0.796l-0.712,0.734l-0.586,0.403h-2.449l-0.469,0.254l-1.209,1.255l-1.11,1.539l-0.89,0.995l-1.083,0.908l-3.175,1.766
l-0.601,0.118l-0.188-0.075l-0.601-0.319l-0.379-0.065l-0.381,0.046l-0.177,0.103l-0.108,0.141l-2.025,1.693l-0.599,0.229
l-0.376,0.066l-0.275,0.171l-0.516,0.42l-0.326,0.139l-0.659,0.169l-0.308,0.131l-0.384,0.314l-0.92,1.011l-0.182,0.319
l-0.197,0.211l-1.664,0.679l-0.827,1.103l-0.49,0.975l-0.125,0.337l-0.289,0.623l-1.384,1.443l-0.231,0.407l-0.072,0.231
l-0.419,0.637l-1.616,1.613l0.038,1.339l-0.125,0.489l-1.269,1.973l-0.217,0.215L435,178.704l-0.217,0.17l-0.14,0.243l-0.151,0.429
l-0.14,0.205l-0.419,0.35l-1.727,1.937l-0.988,0.599l-0.154,0.065l-0.18,0.033l-0.39,0.025l-0.169,0.03l-0.222,0.092l-1.11,0.791
l-0.14,0.075l-0.385,0.055l-0.198,0.05l-0.52,0.305l-0.156,0.062l-0.183,0.018l-0.188-0.005l-0.549-0.135l-0.188-0.013
l-0.231,0.063l-0.13,0.12l-0.106,0.145l-0.864,1.692l-0.665,0.876l-0.072,0.148l-0.053,0.16l-0.01,0.177l0.014,0.18l0.039,0.178
l0.034,0.385l-0.043,0.645l-0.096,0.293l-0.17,0.33l-0.381,0.592l-0.265,0.202l-0.236,0.075l-0.173-0.055l-0.304-0.157l-0.27-0.18
l-0.161-0.07l-0.169-0.062l-0.193-0.045l-0.164-0.062l-0.14-0.088l-0.356-0.34l-0.14-0.085l-0.198-0.067l-0.28-0.04l-10.379,0.087
h-0.794l0.09-1.325l-0.887-3.621l-0.728-3.01l-0.126-0.523l-0.01-0.752l0.206-0.805l1.507-3.557l0.854-2.035l1.166-2.773
l-0.668,0.02l-0.402-0.063l-0.206-0.286l-0.318-6.724l-0.18-0.548l-0.363-0.468l-1.513-1.54l-1.13-1.437l-1.122-1.427l-3.264-4.15
l-2.692-3.421l-2.085-4.033l-2.663-3.196l-2.84-3.406l-3.344-4.007l-2.705-3.248l-2.721-2.503l-3.174-2.918l-0.949-0.59
l-0.024-0.015l-0.183-0.067l0.23-0.796l0.046-0.593l-0.21-2.229l0.027-0.197l0.064-0.169l0.018-0.198l-0.11-0.282l-1.034-0.668
l-0.75-0.78l-0.229-0.414l-0.091-0.564l0.091-0.555l0.549-1.419l-0.091-0.865l-0.256-1.165l-0.119-1.156l0.357-0.855l0.22-0.798
l-0.293-1.155l-1.336-2.714l-0.21-0.648l-0.055-0.676l0.064-0.901l0.11-0.376l0.531-1.145l0.201-1.529l0.128-0.328l0.256-0.3
l0.677-1.294l0.183-0.553l0.046-0.169l-0.073-0.263l-0.137-0.178l-0.201-0.197l-0.421-1.754l-0.256-0.384l-0.275-0.3l-0.247-0.337
l-0.137-0.947l-0.119-0.366l-0.366-0.712l-0.833-1.115l-0.119-0.225l-0.101-0.29l-0.439-0.74l-0.174-0.712l-0.293-0.394
l-0.064-0.244l0.101-0.178l0.43-0.3l0.11-0.169l-0.027-0.89l-0.256-1.939l0.421-1.639l0.073-0.955l-0.174-0.908l-0.485-0.636
l0.512-0.608l1.208-1.067l0.549-0.618l0.238-0.178l0.668-0.234l0.247-0.14l0.174-0.327l0.275-0.814l0.21-0.159l1.345-0.477
l0.567-1.169l0.631-2.927l1.491-3.179l0.082-0.785l-0.092-1.57l0.064-0.766l0.284-1.42l-0.067-3.077l12.965,0.131l1.298-0.036
l0.055,0.174l0.206,0.203l0.289,0.095l0.764,0.036l0.135-0.013l0.414-0.167l0.006-0.029l0.202-0.059l0.122-0.072L399,65.255
l0.276,0.213l0.167,0.239l0.08,0.22l0.141,0.177l0.353,0.115l0.039-0.544l0.341-0.026l0.501,0.2l0.511,0.131l0.729-0.413
l0.035,0.003l1.677-0.443l0.312-0.2l0.218-0.652l0.26-0.439l0.344-0.423l0.376-0.246l-0.241-0.695l0.572-0.341l1.561-0.285
l0.549-0.433l0.97-1.232l0.411-0.282l1.272,0.236l0.88,0.377l0.199,0.056l1.172-0.013l0.414,0.206l0.536,0.652l-0.296,0.708
l0.28,0.6l0.581,0.308l0.601-0.177l0.447-0.029l0.649,0.292l1.134,0.702l0.305,0.125l0.687,0.18l0.299,0.128l0.26,0.207
l0.202,0.213l0.212,0.164l0.283,0.066l0.501,0.243l0.173,0.593l0.071,0.718l0.209,0.633l-0.199,0.476l0.093,0.653l0.279,0.574
l0.357,0.249l1.282-0.22l0.373,0.066l0.482,0.295l0.321,0.092l0.456-0.049l1.131-0.325l0.977-0.148l0.215-0.148l0.161-0.148
l0.148-0.069h3.63l0.244,0.171l0.186,0.745l0.196,0.167l0.173,0.066l0.781,0.39l0.536,0.059l1.95-0.315l0.463-0.161l0.829-0.092
l0.228-0.144l0.167-0.203l0.302-0.249l1.147-0.439l0.357-0.19l0.279-0.262l0.864-1.276l1.407-1.673l0.527-0.177l0.296,0.151
l0.614,0.046l0.771,0.502l0.459,0.089l2.875-0.259l0.292-0.128l0.276-0.21l0.488-0.289l0.373,0.977l0.845,1.062l1.086,0.918
l1.092,0.528l2.641,0.604l1.355,0.148l1.115-0.121l0.302-0.138l0.244-0.157l0.241-0.118l0.292-0.02l0.36,0.171l0.553,0.587
l0.254,0.128l1.539,0.436h0.71l0.591-0.098l0.209-0.111l0.26-0.226l0.174-0.315l0.177-0.44l0.241-0.394l0.376-0.171l0.649-0.138
l1.124-0.597l0.675-0.131l0.986-0.01l0.665-0.118l0.578-0.331l1.384-1.115l2.261-1l0.646-0.456l0.389-0.347l0.254-0.423
l-0.058-0.39l-0.186-0.39l-0.125-0.423l0.164-0.711l0.745-1.334l-0.045-0.57l0.292-0.387l0.479-1.432l0.08-0.465l0.212-0.275
l1.953-1.114l0.514-0.125l1.266-0.079l0.276-0.072l0.292-0.311l1.776-0.305L479.642,54.917z M374.507,80.618l0.238-0.87
l0.329-0.271l0.869,0.43l-0.494,0.271l-0.018,0.355l0.119,0.402l-0.137,0.402l-0.339,0.15l-0.348-0.093l-0.293-0.215l-0.183-0.187
							L374.507,80.618z" />

						   <path class="st0" d="M312.975,229.628l-0.178-0.072l-0.826-0.637l-0.312-1.281l-0.022-0.748l-0.149-0.466l-0.352-0.328l-3.72-1.505
l-4.924-1.997l-3.207-1.296l-2.446-0.375l-6.142,0.154l-3.228-0.519l-0.495-0.241l-0.271-0.492l-0.153-1.239l-0.273-0.572
l-0.369-0.311l-1.304-0.686l-0.601-0.516l-1.882-2.068l-0.854-0.709l-0.764-0.345l-5.188-0.348l-1.198-0.599l-0.466-0.331
l-0.62-0.09l-0.671-0.007l-0.607-0.08l-0.058-0.094l0.042-0.157l0.008-0.15l-0.146-0.084l-0.125,0.013l-0.244,0.06l-0.116-0.023
l-0.194-0.214l-0.098-0.301l-0.135-0.298l-0.308-0.221l-0.604-0.331l-0.39-0.448l-0.583-1.093l-0.443-0.562l-0.511-0.368
l-0.588-0.221l-3.155-0.752l-1.38-0.107l-0.779,0.484l-0.254,0.455l-0.234-0.013l-0.246-0.191l-0.265-0.067l-0.456,0.237
l-0.575,0.655l-0.363,0.314l-0.692,0.304l-0.519-0.074l-0.459-0.354l-0.501-0.535l-1.253-0.886l-1.381-0.291l-3.347,0.023
l-5.071,0.037l-2.231,0.013l-3.488,0.027l-0.039-2.249l-0.064-3.867l-0.048-3.074l-0.045-2.609l0.439-0.18l0.072-0.08l-0.357-0.951
l-0.164-0.253l-0.887-0.947l-0.068-0.447l0.202-0.71l0.572-1.117l0.083-0.323l-0.1-0.31l-0.458-0.64l-0.098-0.37l-0.08-2.099
l-0.132-0.663l-0.218-0.596l-0.325-0.573l-0.392-0.423l-0.447-0.17l-1.012-0.663l-0.771-1.535l-1.16-4.024l-0.151-1.394v-1.437
l-0.222-0.961l0.018-0.21l0.001-0.016l0.511-0.136l2.721-0.329l2.302-1.157l2.893-1.449l3.302-1.216l2.647-0.98l4.314-1.588
l3.087-0.714l4.398-1.016l0.556-0.043l3.219-0.787l4.003-0.976l3.235-0.79l2.666-0.996l2.891-1.533l2.136-1.132l4.443-1.477
l4.089-1.363l3.119-1.038l2.347-1.088l1.19-0.368l2.496-0.461l2.891-0.995l3.267-1.124l4.703-1.617l5.226-1.799l5.352-1.839
l3.909-1.345l2.708-0.93l2.258-0.775l1.712,0.954l0.697,0.55l0.344,0.911l-0.158,1.593l0.135,0.576l0.777,1.289l0.26,0.295
l0.445,0.292l0.276,0.109l0.223,0.209l0.086,0.168l0.432,0.84l0.546,0.766l1.572,3.209l0.565,0.759l0.401,0.325l1.311,0.594
l0.414,0.365l0.204,0.382l0.159,0.401l0.263,0.415l0.357,0.282l0.835,0.391l0.357,0.282l0.263,0.405l0.44,1.214l0.189,0.521
l0.263,0.428l0.369,0.332l0.546,0.169l0.305-0.219l0.1-0.574l0.003-1.109l0.265-0.933l0.471-0.723l0.659-0.209l1.672,1.294
l0.877,0.09l0.883-0.325l1.805-1.108l0.851-0.219l0.909,0.003l3.27,0.441l0.244-0.162l0.36-0.81l0.228-0.328l0.996-0.425
l2.265-0.063l3.074-1.108l0.234-0.03l0.498,0.056l0.247-0.05l0.238-0.156l0.225-0.186l0.234-0.153l0.273-0.046l0.871,0.468
l0.259,0.375l0.415,0.6l1.015,2.093l0.723,0.896l1.619,1.613l0.508,1.019l0.038,1.072l-0.421,1.913l0.296,1.099l0.52,1.036
l0.334,0.947l0.173,1.014l0.01,2.373l0.074,0.539l0.183,0.642l0.279,0.515l0.341,0.482l0.225,0.489l-0.058,0.542l-0.228,0.476
l-0.633,0.885l-0.183,0.452l0.071,0.456l0.196,0.402l0.08,0.366l-0.286,0.353l-0.074,0.549l0.335,2.464l0.311,2.286l-0.122,0.842
l-0.572,0.836l-0.418,0.316l-0.405,0.217l-0.382,0.266l-0.353,0.473l-0.145,0.4l-0.154,0.816l-0.206,0.4l-0.626,0.387l-0.61,0.99
l-0.559,0.29l-0.389,0.54l-0.003,1.087l0.283,1.947l-0.1,1.284l-0.369,1.024l-1.921,2.695l-0.013,0.018l-0.337,0.2l-0.642,0.2
l-0.527,0.254l-0.552,0.427l-0.883,0.961l-0.334,0.811l-0.157,1.129l0.058,1.152l0.337,0.898l0.385,0.341l1.034,0.494l0.453,0.281
l2.592,2.743l0.524,0.735l0.138,0.909l-0.613,2.09l-0.125,1.1l1.282,2.83l0.607,0.626l1.07,0.04l1.378,0.204l0.694,0.723
l0.871,2.243l0.334,0.502l0.389,0.385l1.005,0.767l0.209,0.231l0.164,0.298l0.382,0.961l0.193,0.147l0.234,0.087l1.969,1.055
l0.402,0.446l0.556,1.086l0.315,0.432l1.446,1.234l0.251,0.446l0.225,0.701l0.305,0.496l0.475,0.285l0.546,0.235l0.284,0.208
l0.214,0.157l0.132,0.268l0.1,0.627l0.141,0.245l0.212,0.121l0.475,0.087l0.212,0.081l0.424,0.312l0.267,0.292l0.572,0.772
l0.915,0.732l0.887,0.148l0.925-0.091l1.025,0.03l0.7,0.523l0.286,1.007l0.019,1.178l-0.093,1.044l-0.363,0.89l-0.684,0.49
l-0.787,0.39l-0.674,0.564l-0.244,1.014l1.012,1.566l-0.096,0.837l0.475,0.464l1.635,0.259l3.135,0.107l1.768-0.194l0.127,0.493
l0.158,3.766l0.159,0.622l0.731,1.452l0.223,1.174l-0.011,0.268l-0.382,0.601l-0.09,0.229l-0.039,0.268l-0.031,0.788l0.013,0.141
l0.352,1.845l-0.03,6.327l-0.297,1.386l-0.36-0.226l-0.704-0.57l-1.996-1.02l-0.398-0.292l-0.226-0.351l-0.297-0.135l-0.803-0.741
l-0.972-0.676l-0.5-0.532l-0.41-0.549l-0.145-0.296l-0.042-0.202l0.003-0.617l-0.035-0.185l-0.402-1.16L386,254.852l-0.484-0.535
l-0.136-0.263l-0.273-1.387l-0.906-2.255l-0.324-0.444l-0.064-0.16l-0.114-0.454l-0.506-0.831l-0.121-0.298l-0.169-0.037
l-0.063-0.653l-0.361-1.249l-0.594-1.359l-1.243-2.111l-0.765-3.238l-0.442-0.982l-0.26-0.327l-2.804-2.371l-0.726-0.359
l-2.364-0.678l-2.334-1.285l-5.85-3.216l-1.683-0.538l-2.065-0.396l-2.083-0.114l-0.551-0.211l-0.495-0.33l-1.576-1.341
l-0.242-0.136l-0.328-0.107l-0.678-0.136l-0.326-0.119l-0.504-0.05l-1.103,0.282l-0.504-0.121l-0.236-0.35l-0.114-0.448
l-0.154-0.385l-0.365-0.166l-0.544-0.04l-0.371-0.112l-0.782-0.411l-0.596-0.501l-0.943-1.525l-1.058-1.32l-0.887-1.425
l-0.567-0.661l-0.077,0.109h-1.169l-0.382,0.08l-1.397,0.512l-0.508,0.055l-3.129-0.276l-1.041,0.137l-0.834,0.326l-0.771,0.504
l-0.659,0.652l-1.03,1.551l-0.25,0.194l0.071,0.744l-0.013,0.231l-0.087,0.481l0.019,0.178l0.164,0.275l0.048,0.156l-0.048,0.251
l-0.151,0.3l-1.78,2.263l-0.106,0.198l-0.048,0.251l-0.077,0.189l-0.183,0.144l-0.361,0.122l-0.145,0.07l-0.39,0.282l-0.532,0.6
l-0.096,0.173l-0.068,0.225l-0.029,0.366l0.029,0.46l-0.014,0.174l-0.13,0.532l-0.005,0.183l0.024,0.169l-0.053,0.27l-0.149,0.337
l-1.036,1.514l-0.103,0.215l-1.022,3.003l-0.169,0.749l-0.034,0.379l-0.063,0.131l-0.125,0.183l-0.271,0.247l-0.202,0.131
l-0.202,0.076l-1.089,0.18l-0.17,0.059l-0.145,0.081l-0.111,0.116l-0.198,0.259l-0.111,0.114l-0.26,0.18l-0.328,0.307l-0.246,0.304
l-0.385,0.603l-0.373,1.002l-0.048,0.714l-0.058,0.267l-0.149,0.308l-0.063,0.259l0.019,0.353l0.26,0.775l-0.01,0.198l-0.072,0.267
l-1.156,2.311l-0.262,0.399l-0.308,0.39l-0.034,0.178l0.096,0.283l0.087,0.195l0.058,0.251l0.024,0.187l-0.222,0.917l-0.807,0.331
l0.143-0.222l0.193-0.511l-0.026-0.518l-0.217-0.289l-0.692-0.39l-0.273-0.225l-0.323-0.646l-0.117-0.71l-0.011-0.325l-0.041-1.212
l-0.424-2.008l-0.797-1.876l-1.298-2.319l-1.76-3.141l-0.042-0.208l2.229-2.189l0.488-0.645l0.533-1.044l0.877-3.422l0.215-0.932
l-0.382,0.047l-0.517,0.218l-0.932,0.594l-0.504,0.141l-0.975-0.064l-1.906-0.426l-0.973-0.06l-0.482,0.08l-0.434,0.171
							l-0.267,0.181L312.975,229.628z" />


						   <path class="st0" d="M321.699,251.582l0.807-0.331l0.222-0.917l-0.024-0.187l-0.058-0.251l-0.087-0.195l-0.096-0.283l0.034-0.178
l0.308-0.39l0.262-0.399l1.156-2.311l0.072-0.267l0.01-0.198l-0.26-0.775l-0.019-0.353l0.063-0.259l0.149-0.308l0.058-0.267
l0.048-0.714l0.373-1.002l0.385-0.603l0.246-0.304l0.328-0.307l0.26-0.18l0.111-0.114l0.198-0.259l0.111-0.116l0.145-0.081
l0.17-0.059l1.089-0.18l0.202-0.076l0.202-0.131l0.271-0.247l0.125-0.183l0.063-0.131l0.034-0.379l0.169-0.749l1.022-3.003
l0.103-0.215l1.036-1.514l0.149-0.337l0.053-0.27l-0.024-0.169l0.005-0.183l0.13-0.532l0.014-0.174l-0.029-0.46l0.029-0.366
l0.068-0.225l0.096-0.173l0.532-0.6l0.39-0.282l0.145-0.07l0.361-0.122l0.183-0.144l0.077-0.189l0.048-0.251l0.106-0.198
l1.78-2.263l0.151-0.3l0.048-0.251l-0.048-0.156l-0.164-0.275l-0.019-0.178l0.087-0.481l0.013-0.231l-0.071-0.744l0.25-0.194
l1.03-1.551l0.659-0.652l0.771-0.504l0.834-0.326l1.041-0.137l3.129,0.276l0.508-0.055l1.397-0.512l0.382-0.08h1.169l0.077-0.109
l0.567,0.661l0.887,1.425l1.058,1.32l0.943,1.525l0.596,0.501l0.782,0.411l0.371,0.112l0.544,0.04l0.365,0.166l0.154,0.385
l0.114,0.448l0.236,0.35l0.504,0.121l1.103-0.282l0.504,0.05l0.326,0.119l0.678,0.136l0.328,0.107l0.242,0.136l1.576,1.341
l0.495,0.33l0.551,0.211l2.083,0.114l2.065,0.396l1.683,0.538l5.85,3.216l-1.857,2.157l-0.588,1.331l-0.358,0.568l-0.352,0.373
l-0.631,0.544l-0.202,0.144l-2.276,1.077l-1.558,0.396l-0.262,0.101l-0.93,0.507l-0.487,0.37l-0.198,0.252l-1.844,3.844
l-0.072,0.251l-0.034,0.222l-0.014,0.222l0.106,1.84l-0.082,0.609l-0.236,0.685l-0.559,1.228l-0.443,1.378l-0.243,1.641
l0.006,1.704l-0.116,0.433l-1.254,3.037l-0.072,0.416l0.034,0.462l0.906,3.473l0.058,0.447l-0.419,3.166l-0.01,0.423l0.077,0.37
l0.299,0.238l2.567,0.979l0.173,0.142l0.116,0.137l0.087,0.147l0.39,0.471l0.154,0.147l0.246,0.157l0.111,0.208l0.063,0.336
l-0.169,1.423l0.015,0.095l0.189,0.451l-0.01,0.919l-0.141,1.316l-0.742,2.861l-0.4,1.016l-0.503,0.5l-1.311-0.183l-0.411,0.063
l-0.337,0.282l-0.949,1.317l-0.063,0.046l-0.048,0.02l-0.101,0.051l-0.236,0.051l-0.156-0.022l-0.318-0.095l-0.313-0.127
l-0.434-0.277l-0.304-0.321l-0.127-0.101l-0.4-0.259l-0.125-0.105l-0.212-0.237l-0.077-0.152l-0.053-0.178l-0.067-0.411
l-0.111-0.36l-0.313-0.605l-0.101-0.127l-0.429-0.423l-0.079-0.064l-0.096-0.061l-0.299-0.101l-0.92-0.147l-0.18-0.059
l-0.342-0.047l-0.198,0.446l-0.193,1.026l-0.082,0.21l-0.096,0.166l-0.332,0.338l-0.39,0.296l-0.744,0.406l-0.506,0.402
l-0.106,0.118l-0.357,0.531l-0.864,0.932l-0.048,0.213l0.024,0.183l0.068,0.162l0.734,1.342l0.13,0.328l0.053,0.179l0.019,0.201
l-0.005,0.198l-0.058,0.369l-0.318,0.99l-0.026,0.178l0.016,0.169l0.071,0.124l0.315,0.056l0.154,0.056l0.072,0.152l0.072,0.401
l0.053,0.18l0.077,0.158l0.183,0.274l0.082,0.18l0.058,0.202l-0.005,0.32l-0.111,0.212l-0.525,0.715l-0.164,0.112l-0.165,0.02
l-0.177,0.052l-0.189,0.125l-0.241,0.369l-0.323,0.357l-0.27,0.183l-0.154,0.158l-0.164,0.21l-0.508,0.818l-0.93,0.954
l-0.448,0.573l-0.165,0.353l-0.313,1.165l-0.043,0.397l0.344,0.783l0.996,0.064l0.439,0.108l1.073,0.68l1.107,0.346l0.312,0.231
l0.212,0.351l0.119,0.351l0.151,0.331l0.316,0.278l0.356,0.143l0.333,0.088l0.312,0.131l0.294,0.273l0.196,0.353l0.212,0.752
l0.159,0.372l0.283,0.334l0.303,0.131l0.742,0.132l0.371,0.216l0.202,0.29l0.173,0.323l0.291,0.312l0.378,0.143l0.239-0.031
l-0.169,1.058l-3.121,11.64l-0.01,8.242l-0.072,0.547l-0.212,0.194l-0.366,0.09l-0.797,0.022l-0.332,0.044l-0.26,0.087
l-0.313,0.211l-0.096,0.206l-0.034,0.215l0.077,0.365l0.053,0.174l0.072,0.171l0.766,1.247l0.039,0.421l-0.005,0.667l-0.188,1.595
l0.014,0.64l0.043,0.34l0.212,0.017l0.667-0.01l0.178,0.044l0.145,0.082l0.111,0.109l0.212,0.463l0.101,0.125l0.14,0.106
l0.164,0.063l0.188,0.019l0.377-0.005l0.164,0.051l0.145,0.079l0.135,0.113l0.27,0.321l0.072,0.072l0.111,0.08l0.506,0.225
l0.077,0.048l-0.125,0.077l-0.53,0.22l-0.323,0.056l-0.39,0.015l-0.077,0.015l-0.048,0.031l-0.174,0.237l-0.096,0.082l-0.773,0.453
l-0.149,0.152l-0.125,0.189l-0.246,0.502l-0.101,0.137l-0.101,0.094l-0.262,0.197l-1.026,0.395l-0.236,0.128l-0.173,0.118
l-0.349,0.331l-0.058,0.043l-0.096,0.06l-0.207,0.079l-0.761,0.142l-0.424,0.186l-0.064,0.041l-0.039,0.036l-0.814,1.407
l-0.092,0.07l-0.125-0.015l-0.361-0.01l-0.14-0.031l-0.077-0.044l-0.035-0.027l-0.593-0.653l-0.246-0.207l-0.14-0.08l-0.308-0.135
l-0.135-0.089l-0.077-0.154l-0.14-0.581l-0.087-0.143l-0.112-0.111l-0.149-0.055l-0.164,0.005l-0.159,0.055l-0.718,0.441
l-0.173,0.06l-0.188,0.033l-0.604-0.012l-0.207,0.017l-0.357,0.106l-0.149,0.084l-0.13,0.103l-0.323,0.355l-0.141,0.089
l-0.318,0.147l-0.357,0.118l-0.159,0.073l-0.149,0.087l-0.13,0.098l-0.308,0.301l-0.154,0.229l-2.87,2.395l-0.111,0.19l-0.019,0.2
l0.776,2.781l0.087,0.14l0.12,0.108l0.801,0.305l0.26,0.195l0.096,0.134l0.063,0.166l0.043,0.199l0.063,0.661l0.092,0.387
l0.087,0.156l0.385,0.534l0.17,0.315l0.063,0.182l0.039,0.231l-0.029,0.185l-0.618,1.668l-0.096,0.457l-0.01,0.331l0.048,0.212
l0.014,0.302l-0.024,0.404l0.024,0.439l0.106,0.434l7.785,8.188l0.434,0.237l3.699,1.343l0.337,0.28l0.13,0.392l0.055,0.438
l0.138,6.835l0.103,0.346l0.251,0.143l0.39,0.069l2.512,0.213l0.315,0.103l3.129,2.44l0.204,0.231l0.14,0.217l0.096,0.339
l0.005,0.239l-0.034,0.203l-0.178,0.487l-0.083,0.145l-0.096,0.133l-0.111,0.117l-0.26,0.203l-0.622,0.324l-0.337,0.136
l-0.173,0.124l-0.154,0.193l-0.18,0.362l-0.048,0.3l0.01,0.308l0.063,0.374l-0.014,0.246l-0.058,0.188l-0.116,0.117l-0.467,0.258
l-0.145,0.145l-0.159,0.472l-0.077,0.434l-0.169,2.342l-1.558,0.14l-0.532,0.317l-0.636,0.723l-0.867,1.238l-0.104,0.266
l-0.082,0.302l-0.122,0.243l-0.223,0.1l-0.108,0.088l-0.332,0.155l-0.35,0.065l-0.164-0.185l-0.28-0.53l-0.662-0.193l-0.774,0.024
l-1.747,0.305l-5.392-0.243l-1.243,0.138l-1.087,0.385l-2.123,1.698l-0.654,0.406l-1.269,0.288l-1.155,0.589l-0.649,0.202
l-0.737-0.035l-0.109-0.043l-1.12-0.459l-0.705-0.126l-0.562,0.098l-1.879,0.834l-0.569,0.373l-0.62,0.573l-0.5,0.641l-0.202,0.57
l-0.275,0.563l-0.598-0.185l-0.594-0.456l-0.251-0.254l-0.718-0.052l-0.464,0.024l-0.247-0.283l-0.076-0.976l-0.21-0.502
l-0.488-0.456l-0.567-0.342l-0.432-0.171l-0.466-0.014l-1.781,0.242l-0.596-0.057l-0.524-0.154l-0.467-0.217l-3.501-2.713
l-1.211-0.528l-0.625-0.088l-2.008,0.088l-0.488-0.21l-1.751-1.112l-1.338-1.181l-0.038-0.144l-2.265-3.895l0.039-0.131l0.12-0.183
l0.114-0.21l0.003-0.224l-0.09-0.069l-0.137,0.007l-0.14-0.021l-0.28-0.386l-0.451-0.286l-0.172-0.293l2.403-3.102l1.571-2.023
l0.726-1.458l0.193-1.272l-0.427-3.454l-0.446-3.569l0.061-1.373l0.328-1.411l0.98-1.276l1.349-0.175l1.465,0.182l1.33-0.226
l0.787-0.868l1.015-1.992l0.977-1.91l2.017-2.508l1.381-1.72l0.241-0.425l0.17-0.476l0.222-1.013l-0.016-0.264l-0.209-0.445
l-0.038-0.229l0.067-0.322l0.424-0.784l0.154-0.513l0.231-1.611l0.283-0.298l0.506-0.096l0.493-0.154l0.241-0.475l0.18-1.012
l0.424-0.373l1.664,0.058l-0.09-0.256l-0.186-0.773l0.112-2.444l0.601-1.947l0.048-1.363l0.21-0.775l0.098-0.635l-0.312-0.365
l-0.689-0.29l-0.67-0.427l-0.517-0.529l-0.257-0.58l0.257-1.279l-0.045-0.669l-0.553-0.314l-0.578,0.198l-0.928,0.959l-0.7,0.195
l-0.716-0.038l-0.562-0.13l-0.355-0.358l-0.104-0.733l0.1-0.334l0.183-0.286l0.132-0.317l-0.045-0.43l-0.077-0.324l-0.01-0.303
l0.222-2.386l-0.01-0.607l-0.212-0.716l-0.437-0.153l-0.565,0.072l-0.596-0.027l-0.329-0.392l-0.035-0.678l0.196-1.339
l-0.064-0.967l0.074-0.324l1.16-1.324l0.453-0.728l0.247-0.551l0.109-0.589l0.032-0.813l0.903-4.078l-0.198-1.129l-0.262-0.323
l-0.291-0.231l-0.246-0.255l-0.154-0.404l-0.071-0.384l-0.093-0.224l-0.18-0.15l-0.337-0.139l-0.54-0.061l-1.931,0.292l-0.422-0.02
l-0.352-0.122l-0.246-0.262l-0.076-0.438l0.051-0.418l-0.051-0.36l-0.234-0.734l0.045-0.404l0.302-0.275l0.398-0.224l0.332-0.251
l-0.39-0.261l-0.199-0.482l-0.215-1.772l0.048-0.295l2.991-1.412l0.941-0.139l1.272,0.098l0.819-0.071l0.538-0.438l0.334-0.706
l0.217-0.878l0.064-1.085l-0.241-0.895l-0.935-1.865l-0.398-1.176l-0.106-0.674l0.077-0.552l0.385-0.447l0.533-0.224l0.591-0.173
l0.554-0.295l0.605-0.366l0.683-0.322l0.554-0.423l0.206-0.664l-0.109-0.335l-0.395-0.471l-0.045-0.322l0.088,0.024l0.654-0.958
l0.896-0.684l0.146-0.362l-0.242-0.626l-0.562-0.629l-1.452-1.184l-0.472-0.693l-0.138-0.491l0.055-0.271l0.183-0.25l0.241-0.433
l0.278-0.7l0.021-0.243l-0.101-1.082l-1.094-3.373l-0.068-0.736l0.167-0.645l0.029-0.28l-0.381-0.456l-0.05-0.307l0.157-0.26
l0.247-0.162l0.241-0.219l0.124-0.425l-0.058-0.577l-0.368-0.915l-0.048-0.638l0.087-0.638l0.199-0.678l0.341-0.547l0.514-0.24
l0.793-0.533l0.127-1.194l-0.092-1.43l0.119-1.251l0.514-1.21l0.122-0.627l-0.129-0.674l-0.427-0.573l-1.166-0.536l-0.543-0.428
							l-0.138-0.27l-0.328-1.101l-0.003-0.36l0.199-0.219l0.27-0.175l0.202-0.253l1.221-2.394L321.699,251.582z" />

						   <path class="st0" d="M302.954,380.284l1.201,0.779l-0.042-0.156l1.338,1.181l1.751,1.112l0.488,0.21l2.008-0.088l0.625,0.088
l1.211,0.528l3.501,2.713l0.467,0.217l0.524,0.154l0.596,0.057l1.781-0.242l0.466,0.014l0.432,0.171l0.567,0.342l0.488,0.456
l0.21,0.502l0.076,0.976l0.247,0.283l0.464-0.024l0.718,0.052l0.251,0.254l0.594,0.456l0.598,0.185l0.275-0.563l0.202-0.57
l0.5-0.641l0.62-0.573l0.569-0.373l1.879-0.834l0.562-0.098l0.705,0.126l1.12,0.459l-0.182,0.72l-0.14,0.345l-0.154,0.299
l-0.92,1.14l-0.493,0.864l-0.202,0.263l-0.217,0.238l-0.487,0.439l-0.642,0.741l-0.241,0.436l-0.096,0.133l-0.159,0.29
l-0.058,0.166l-0.043,0.182l-0.024,0.192l-0.01,0.207l0.015,0.214l0.419,1.746l0.05,0.382l-0.016,0.272l-0.111,0.344l-0.072,0.368
l-0.005,0.41l0.058,0.237l0.13,0.23l3.174,4.023l2.123,1.694l0.058,0.099l0.042,0.177l-0.003,0.837l0.048,0.501l0.13,0.509
l0.988,1.53l0.058,0.376l0.014,0.267l-0.096,0.834l-0.487,2.17l0.01,0.217l0.149,0.812l0.039,0.628l-0.043,0.609l0.029,0.43
l0.342,1.356l0.072,0.646l-0.019,0.198l-0.053,0.17l-0.116,0.116l-0.154,0.087l-0.898,0.306l-0.28,0.193l-0.111,0.116l-0.077,0.146
l-0.043,0.181l-0.063,1.258l-0.029,0.189l-0.328,1.018l-0.299,1.912l0.005,0.212l0.019,0.219l0.087,0.426l0.323,0.807l1.862,2.939
l0.198,0.209l0.178,0.151l0.411,0.28l0.12,0.111l0.212,0.251l0.376,0.55l0.26,0.47l0.573,0.826l0.58,0.583l0.925,0.651l0.207,0.096
l0.267,0.155l0.737,0.648l0.564,0.354l0.251,0.218l0.209,0.261l0.178,0.298l0.202,0.265l0.13,0.106l0.694,0.455l0.241,0.227
l0.411,0.525l0.496,0.919l1.095,1.549l0.207,0.667l0.077,0.611l-0.01,0.606l0.048,0.208l0.202,0.232l0.169,0.14l0.169,0.107
l0.125,0.105l0.313,0.4l0.116,0.115l0.262,0.201l0.371,0.407l0.332,0.445l0.241,0.166l0.227,0.105l0.164,0.058l0.159,0.115
l0.363,0.407l0.169,0.138l0.188,0.229l0.193,0.288l0.703,1.303l0.101,0.135l0.111,0.122l0.209,0.271l0.072,0.173l0.053,0.199v0.528
l0.043,0.281l0.096,0.299l0.414,0.711l0.121,0.294l0.048,0.43v0.937l0.063,0.241l0.111,0.229l0.265,0.297l0.194,0.152l0.332,0.194
l0.111,0.126l0.072,0.142l-0.034,0.215l-0.091,0.147l-0.149,0.105l-0.898,0.283l-0.357,0.009l-0.164,0.031l-0.121,0.095
l-0.067,0.154l-0.019,0.194l0.01,0.208l0.043,0.236l0.087,0.252l0.342,0.585l0.043,0.28l-0.005,0.193l0.019,0.189l0.077,0.179
l0.318,0.287l0.135,0.186l0.101,0.247l0.096,0.469l0.088,0.279l0.138,0.201l0.055,0.059l0.043,0.028l0.169,0.058l0.173,0.021
l0.414-0.005l0.222,0.11l0.198,0.231l0.416,0.827l0.061,0.254l-0.077,0.214l-0.111,0.151l-0.116,0.133l-0.077,0.14l-0.01,0.181
l0.077,0.198l0.185,0.263l0.096,0.193l-0.039,0.238l-0.108,0.14l-0.149,0.116l-0.159,0.084l-0.14,0.095l-0.082,0.146l-0.053,0.17
l-0.145,0.314l-0.096,0.138l-0.106,0.121l-0.525,0.428l-0.082,0.144l-0.058,0.168l0.013,0.182l0.074,0.147l0.212,0.074l0.178-0.026
l0.178-0.068l0.304-0.174l0.164-0.063l0.169-0.014l0.149,0.063l0.135,0.163l0.469,0.886l0.072,0.237l-0.014,0.245l-0.082,0.168
l-0.222,0.258l-0.083,0.147l-0.022,0.389l-0.035,0.163l-0.361,0.551l-0.063,0.168l-0.029,0.849l-0.082,0.595l0.019,0.537
l-0.116,0.377l-0.029,0.248l0.034,0.337l0.13,0.63l-0.014,0.316l-0.048,0.241l-0.053,0.165l-0.019,0.193l0.212,2.319l-0.082,0.631
l0.039,0.353l-0.039,0.206l-0.048,0.139l-0.034,0.042l-0.039,0.067l-0.227,0.505l-0.101,0.345l-0.019,0.202l0.01,0.202l0.236,0.999
l0.019,0.253l-0.005,0.313l0.034,0.09l0.072-0.018l0.217-0.222l0.207-0.09l0.214-0.032l0.178,0.042l0.169,0.06l0.308,0.153
l0.159,0.063l0.173,0.019l0.303-0.056l0.087,0.058l0.145,7.332l0.068,0.518l0.132,0.545l3.819,6.659l0.226,0.286l0.222,0.083
l1.283-0.443l1.315-0.27l0.368-0.122l0.318-0.173l0.135-0.104l0.26-0.154l0.063-0.027l0.063-0.016l0.361-0.058l0.174-0.055
l0.159-0.083l0.416-0.298l0.178-0.064l0.212-0.027l0.4-0.009l0.183-0.042l0.438-0.27l0.194-0.048l0.626-0.027l0.197-0.032
l0.174-0.062l0.806-0.393l0.183-0.025l0.169,0.041l0.154,0.069l0.096,0.132l0.005,0.191l-0.222,0.357l-0.941,0.949l-0.246,0.337
l-0.149,0.415l0.289,0.505l2.345,2.398l0.116,0.24l-0.164,0.182l-4.428,2.319l-0.385,0.263l-0.27,0.286l0.048,0.385l0.116,0.509
l0.058,0.483l0.125,0.479l0.183,0.373l0.28,0.338l0.541,0.136l2.43,0.081l0.935,0.377l0.957,1.681l-4.065,1.742l-0.812,0.24
l-3.453,1.462l-1.73,0.279l-6.92,2.584l-6.361,2.362l-4.474,2.386l-3.746,1.325l-4.57,2.539l-1.257,0.336v-0.235l0.506-0.229
l0.516-0.35l0.435-0.411l0.263-0.436l-0.415,0.134l-1.262,0.632l-0.273,0.319l-0.379,0.285l-0.766,0.084l-0.569,0.224l0.212,0.713
l0.182-0.12l0.455-0.162l-0.455,0.761l-0.713,0.459l-1.594,0.705l-0.655,0.459l-0.054-1.53l0.024-0.192l0.14-0.549l0.125-0.78
l0.005-0.21l-0.087-0.639l-0.053-0.19l-0.145-0.346l-0.265-0.467l-0.149-0.176l-0.217-0.176l-0.44-0.238l-0.525-0.176l-2.208-0.249
l-0.13-0.044l-0.106-0.302l-0.05-0.319l0.006-0.16l0.019-0.117l0.13-0.445l0.024-0.192l-0.164-1.047l0.014-0.6l-0.05-0.314
l-0.048-0.151l-0.019-0.135l0.035-0.149l0.051-0.363l-0.029-0.197l-0.125-0.199l-0.222-0.176l-0.458-0.209l-0.308-0.08
l-0.689-0.067l-0.18-0.064l-0.149-0.138l-0.077-0.354l-0.207-3.609l0.043-0.147l0.082-0.14l0.106-0.115l0.106-0.135l0.087-0.142
l-0.014-0.234l-0.092-0.333l-0.299-0.574l-0.024-0.521l0.048-0.515l0.516-1.886l-0.051-0.41l-0.267-0.249l-1.587-0.658
l-0.419-0.271l-0.482-0.401l-0.236-0.248l-0.145-0.225l-0.145-0.847l-0.116-0.376l-0.156-0.332l-1.919-2.953l-1.19-1.418
l-0.087-0.157l-0.072-0.169l-0.055-0.194l-0.149-3.38l0.029-0.52l-0.019-0.15l-0.063-0.169l-0.106-0.162l-0.188-0.164l-0.178-0.092
l-0.781-0.261l-0.376-0.208l-0.137-0.101l-0.149-0.079l-0.193-0.058l-0.251-0.014l-0.626,0.132l-0.749,0.273l-0.197,0.049
l-0.217,0.025l-0.28-0.021l-0.308-0.074l-0.469-0.205l-0.867-0.55l-0.931-0.365l-0.173-0.122l-1.678-1.893l-0.251-0.219
l-0.279-0.185l-0.491-0.197l-3.646-0.529l-0.758-0.395l-0.265-0.07l-1.002-0.174l-0.565-0.196l-0.135-0.1l-0.39-0.234l-1.813-0.282
l-0.744-0.233l-0.284-0.051l-0.236-0.016l-0.231,0.014l-3.887,1.911l-0.491,0.308l-1.767-3.849L286,475.681l-0.204-5.393
l-0.021-0.558l-0.167-0.541l-0.366-0.436l-2.008-1.486l-0.586-0.555l-0.435-0.713l-1.261-2.979l-1.2-4.244l-0.613-1.227
l-2.984-3.237l-0.58-1.205l-0.191-1.397l0.652-7.726l-0.19-1.024l-2.136-6.689l-1.282-4.01l-1.6-5.006l-1.603-5.008l-1.375-4.29
l0.305-0.605l2.48-1.758l2.024-1.444l1.616-1.805l1.629-1.825l1.632-1.824l1.629-1.824l1.629-1.827l1.625-1.822l1.632-1.822
l1.629-1.825l1.632-1.82l1.629-1.82l1.629-1.819l1.629-1.822l1.629-1.818l1.632-1.817l1.629-1.816l1.632-1.816l1.625-1.815
							l1.507-1.676L302.954,380.284z" />

						   <path class="st0" d="M409.531,189.211l0.002-0.03h0.794l10.379-0.087l0.28,0.04l0.198,0.067l0.14,0.085l0.356,0.34l0.14,0.088
l0.164,0.062l0.193,0.045l0.169,0.062l0.161,0.07l0.27,0.18l0.304,0.157l0.173,0.055l0.236-0.075l0.265-0.202l0.381-0.592
l0.17-0.33l0.096-0.293l0.043-0.645l-0.034-0.385l-0.039-0.178l-0.014-0.18l0.01-0.177l0.053-0.16l0.072-0.148l0.665-0.876
l0.864-1.692l0.106-0.145l0.13-0.12l0.231-0.063l0.188,0.013l0.549,0.135l0.188,0.005l0.183-0.018l0.156-0.062l0.52-0.305
l0.198-0.05l0.385-0.055l0.14-0.075l1.11-0.791l0.222-0.092l0.169-0.03l0.39-0.025l0.18-0.033l0.154-0.065l0.988-0.599l1.727-1.937
l0.419-0.35l0.14-0.205l0.151-0.429l0.14-0.243l0.217-0.17l0.222-0.138l0.217-0.215l1.269-1.973l0.125-0.489l-0.038-1.339
l15.544,0.238l0.217,0.226l0.52,0.388l0.463,0.424l0.421,0.467l0.183,0.26l0.072,0.145l0.058,0.16l0.077,0.346l0.072,0.146
l0.092,0.128l0.212,0.231l0.159,0.283l0.623,1.744l0.145,0.18l0.236,0.183l1.026,0.501l0.101,0.12l0.149,0.321l0.106,0.176
l0.18,0.203l0.173,0.045l0.149-0.038l0.703-0.389l0.159-0.055l0.546-0.095l0.164-0.047l0.154-0.067l0.14-0.082l0.12-0.1
l0.193-0.248l0.604-1.007l0.212-0.236l0.439-0.331l0.154-0.06l0.217-0.05l0.395-0.022l0.241,0.075l0.512,0.273l0.188,0.12
l0.13,0.135l0.337,0.536l0.072,0.148l0.159,0.155l0.236,0.16l0.517,0.188l0.265,0.141l0.154,0.155l0.12,0.416l0.207,0.235
l0.227,0.087l0.366,0.03l0.222,0.053l0.165,0.09l0.313,0.31l0.342,0.198l0.26,0.077l0.236,0.018l0.178-0.03l0.324-0.108
l0.834-0.479l0.116-0.103l0.308-0.366l0.14-0.1l0.185-0.055l0.472-0.03l0.188-0.033l0.159-0.052l0.28-0.158l0.255-0.188
l0.193-0.076l0.252-0.048l0.439-0.01l0.241,0.06l0.173,0.098l0.101,0.117l0.439,0.663l0.202,0.243l0.13,0.133l0.18,0.122
l0.275,0.155l0.222,0.045l0.178-0.025l0.357-0.15l1.12,0.02l0.212,0.083l0.149,0.102l0.164,0.25l0.106,0.115l0.357,0.316
l0.103,0.12l0.08,0.14l0.067,0.151l0.257,0.213l0.429,0.245l1.543,0.619l0.323,0.198l0.434,0.36l0.349,0.075l0.4,0.16l0.357,0.253
l0.535,0.303l0.125,0.142l0.043,0.155l-0.019,0.175l-0.043,0.165l0.014,0.177l0.101,0.165l0.45,0.286l0.14,0.125l0.082,0.133
l0.284,0.59l0.164,0.27l0.414,0.481l0.454,0.295l0.501,0.255l0.308,0.105l1.654,0.245l0.198-0.005l0.371-0.055l0.705-0.192
l0.236-0.033l0.164,0.055l0.93,0.488l0.117,0.082l0.222,0.193l0.371,0.273l0.202,0.22l0.101,0.147l0.038,0.043l0.284,0.192
l0.294,0.152l0.159,0.14l0.111,0.15l0.096,0.337l0.079,0.163l0.106,0.153l0.559,0.332l0.462,0.335l0.308,0.113l0.777,0.15
l0.217,0.075l0.265,0.188l0.121,0.105l0.207,0.237l0.077,0.142l0.175,0.482l0.1,0.537l0.055,0.16l0.241,0.217l0.405,0.243
l1.428,0.644l1.524,0.374l0.308,0.14l0.198,0.155l0.058,0.157l0.125,0.519l0.14,0.299l0.101,0.12l0.12,0.105l0.405,0.285
l0.106,0.118l0.096,0.127l1.651,1.108l0.227,0.19l0.154,0.275l0.197,0.461l0.631,0.915l0.077,0.145l0.053,0.16l0.159,0.703
l0.067,0.202l0.117,0.219l0.251,0.289l0.12,0.202l0.053,0.195l-0.019,0.16l0.029,0.197l0.096,0.237l0.535,0.588l0.145,0.185
l0.067,0.15l0.039,0.175l0.005,0.192l-0.014,0.204l-0.077,0.379l-0.005,0.195l0.024,0.175l0.072,0.164l0.178,0.291l0.633,0.792
l0.665,1.1l0.602,1.576l0.043,0.167l0.03,0.184l-0.026,0.384l-0.048,0.181l-0.058,0.165l-0.323,0.59l-0.043,0.172l-0.019,0.194
l0.024,0.383l-0.01,0.204l-0.063,0.395l-0.005,0.197l0.043,0.169l0.4,1.127l0.294,0.577l0.103,0.335l0.154,0.285l0.385,0.487
l0.347,0.532l0.149,0.295l0.236,0.636l0.347,0.512l0.045,0.09l0.149,0.417l0.183,0.896l0.058,0.156l0.077,0.144l0.376,0.512
l0.164,0.276l0.12,0.315l0.183,0.687l0.101,0.69l0.005,0.094l0.043,0.231l0.039,0.436l-0.019,0.405l-0.024,0.186l-0.063,0.163
l-0.096,0.129l-0.149,0.095l-0.193,0.07l-0.492,0.084l-0.323,0.097l-0.329,0.164l-0.111,0.092l-0.106,0.124l-0.087,0.136
l-0.067,0.162l-0.043,0.189l-0.048,1.074l0.034,0.387l0.058,0.225l0.092,0.236l0.193,0.33l0.154,0.156l0.127,0.092l0.867,0.389
l0.226,0.156l0.159,0.146l0.169,0.277l0.324,0.77l0.255,0.409l0.284,0.594l0.024,0.178l0.087,0.27l0.145,0.312l0.792,0.983
l0.202,0.478l0.096,0.161l0.251,0.336l0.082,0.163l0.024,0.185l0.005,0.2l-0.067,0.811l0.034,0.317l0.044,0.044l-0.005,0.529
l-0.341,0.444l-0.544,0.28l-0.612,0.172l0.546,0.198l0.465-0.148l0.281,0.005v0.629l-0.19,0.584l-0.349,0.526l-0.45,0.418
l-0.496,0.246l-1.118,0.127l-1.085-0.066l-1.032,0.098l-1.798,1.222l-1.153,0.381l-4.887,0.458l-0.47-0.18l-0.736-0.778
l-0.514-0.151l0.683,0.929l-0.564,0.691l-1.146,0.442l-1.07,0.177l0.134-0.595l-1.452,1.485l-0.486-0.008l-0.336-0.238
l-0.243-0.249l-0.205-0.061l-0.248,0.204l-0.121,0.249l0.015,0.278l0.142,0.27l-8.782,3.519l-0.405,0.297l-0.708,0.824l-0.44,0.31
l-0.526-0.024l-0.566-0.747l-0.046-2.193l-0.45-0.635l0.212,0.667l-0.369,0.146l-0.395-0.026l-0.379-0.143l-0.341-0.196
l0.433,0.665l0.728,0.763l0.357,0.718l-0.67,0.54l0.617,0.426l-0.721,0.564l-2.016,0.787l-4.072,0.89l-1.715,0.922l-4.017,2.765
l-0.314,0.053l0.083-0.424l-0.777,0.329l-1.148,1.002l-1.239,0.849l-1.077-0.159l-0.766,0.87l-3.994,2.383l-0.832,0.839
l-0.468,0.34l-0.45,0.117l-0.319-0.276l-0.526,0.754l-0.309,0.26l-0.44,0.101l-0.83,0.019l-0.316,0.191l-0.124,0.446l-0.347,0.526
l-0.797,0.526l-0.926,0.279l-0.713-0.207l-0.137,1.206l-0.885,1.129l-2.18,1.921l-2.266,3.804l-0.916,0.878l-0.013-0.253
l-0.2-0.638l-0.212,0.223l-0.278-0.42l-0.273-0.314l-0.319-0.245l-0.41-0.218l-0.44-0.34l-0.111-0.404l0.005-0.455l-0.101-0.502
l-0.635-0.726l-0.895-0.319l-0.82,0.207l-0.41,0.837l0.999-0.665l0.678,0.526l0.433,1.095l0.243,1.048l0.149,1.21l0.23,0.418
l1.04,0.255l0.359,0.229l0.344,0.295l0.425,0.271l-0.951,1.807l-0.427,0.556l-0.804,0.846l-0.266,0.463l-0.099,0.591l-0.245,0.397
l-0.589-0.093l-0.711-0.237l-0.594-0.048l-0.473,0.309l-0.316,0.471l-0.486,1.007l0.584-0.221l0.911-0.674l0.435-0.221
l-0.367,0.687l-2.158,2.658l-1.265,1.071l-2.919,3.43l-1.452,1.352l-1.024,0.232l-0.225,1.32l-0.2,0.472l-0.2-0.147l-0.648-0.301
l0.104,0.648l0.109,0.224l-0.4-0.04l-0.572,0.016l-0.427,0.115l0.018,0.256l0.187,0.4l-0.157,0.384l-0.557,0.683l-1.214,2.616
l-0.486,0.742l0.056-0.433l-0.063-0.288l-0.167-0.096l-0.25,0.144l-0.121,0.256l-0.099,0.716l-0.271,0.7l0.253,0.091l0.291-0.136
l-0.053-0.259l0.114,0.069l0.215,0.091l0.096,0.064l0.035,0.102l0.005,0.318l0.405,0.751l-0.271,0.323l-0.519,0.195l-0.407,0.214
l-0.584,0.224l-0.703-0.224l-0.701-0.369l-0.569-0.192l-0.721-0.713l-1.06-2.113l-0.04,1.015l0.675,2.276l0.175,1.178l-0.43,1.072
l0.271,0.083l0.2,0.158l0.121,0.23l0.046,0.302l-0.121,0.147l-0.273-0.051l-0.43-0.201l-1.022,0.166l-0.911,0.283l-1.1,0.118
l-0.463-0.465l-0.152-1.892l0.066-0.246l0.124-0.305v-0.111l0.005-0.004l0.042-0.03l0.443-0.105l0.427-0.249l0.363-0.355
l0.549-0.836l-0.048-0.202l-0.556-0.605l-0.543-0.806l-0.763,0.293l-0.57-0.141l-0.122-0.327l0.586-0.273l-0.228-0.614
l-0.149-1.071l0.122-1.019l0.594-0.453l0.549-0.083l0.074-0.229l-0.159-0.354l-0.146-0.452l0.038-1.511l-0.145-0.371l-0.202-0.388
l-0.43-1.248l-0.015-0.266l-0.212-0.169l-0.149-0.396l-0.178-0.797l-0.27-0.76l-0.313-0.609l-0.45-0.54l-0.665-0.327l-0.331-0.327
l-0.421-0.563l-0.329-0.228l-1.333-0.496l-1.903-0.962l-0.626-0.648l0.082-0.84l-0.202-0.238l-0.472-0.878l-0.227-0.287
l-0.816-0.83l0.331-0.663l-0.615-0.525l-1.548-0.72l-1.132-0.968l-0.63-0.353l-0.981-0.236l-0.74-0.441l-0.451-0.132l-0.297,0.041
l-0.789,0.203h-0.4l-0.419-0.176l-0.956-0.947l-0.392-0.236l-0.393-0.172l-0.347-0.223l-0.255-0.385l-0.373-0.885l-0.26-0.373
l-0.381-0.341l-1.139-0.631l-0.26-0.356l-0.157-0.533l-0.194-1.168l-0.183-0.533l-0.169-0.041l-0.14-0.253l-0.536-0.471
l-0.183-0.317l-0.414-0.238l-1.033-1.297l-0.342-0.293l-0.39-0.25l0.297-1.386l0.03-6.327l-0.352-1.845l-0.013-0.141l0.031-0.788
l0.039-0.268l0.09-0.229l0.382-0.601l0.011-0.268l-0.223-1.174l-0.731-1.452l-0.159-0.622l-0.158-3.766l-0.127-0.493l0.008-0.001
l0.234-0.37l0.17-0.447l0.08-0.541l-0.042-0.659l-0.273-1.223l0.039-0.524l0.331-0.595l0.157-0.423l-0.186-0.279l-1.005-0.601
l-0.145-0.151l-0.013-0.205l0.151-0.165l0.189-0.144l0.1-0.151l-0.071-1.232l0.074-0.685l0.777-0.665l-0.157-0.809l-0.623-1.265
l-0.125-0.644l-0.09-2.147l-0.096-0.171l-0.749-0.825l-0.087-0.06l-0.263-0.117l-0.096-0.064l-0.122-0.174l-0.161-0.335
l-0.141-0.154l-0.199-0.111l-0.681-0.265l-0.286-0.05l-0.347-0.114l-0.18-0.271l-0.129-0.335l-0.498-0.801l-0.511-1.441
l0.395-0.278l2.168-1.454l0.427-0.452l0.17-0.579l-0.01-1.289l0.122-0.437l0.045-0.162l0.732-0.891l0.122-0.328l-0.373-0.91
l-0.071-0.341l0.212-1.499l0.572-1.215l0.684-0.818l0.18-0.216l2.165-1.977l0.585-0.401l0.704-0.184l0.614,0.033l0.639,0.177
l0.514,0.348l0.225,0.542l0.267,0.251l0.414,0.201l0.389,0.017l0.202-0.298l0.093-0.502l0.154-0.191l0.752-0.077l2.965-0.899
l0.321-0.017l0.588,0.11l0.286-0.027l0.328-0.167l2.252-1.575l0.469-0.535l0.296-0.699l0.032-0.508l-0.173-0.986l-0.013-0.511
							l0.215-0.823l0.315-1.208l0.312-4.648l0.196-2.86l0.276-4.032L409.531,189.211z" />

						   <path class="st0" d="M310.731,537.049l-0.584,0.566L309.91,538l-0.167,0.205l-0.82,0.692l-0.283,0.172l-0.369,0.028l-0.266-0.096
							l-0.637-0.405l-0.524-0.172l-0.531-0.014l0.319-1.073l0.803-1.834l2.408,0.744L310.731,537.049z" />

						   <path class="st0" d="M386.995,369.033v0.005l-0.142,0.214l-0.005,0.163l0.384,0.076l-0.212,0.734l-0.174,0.341l-0.25,0.306
l-0.339-0.222l-0.344-0.035l-0.268,0.179l-0.111,0.409l0.096,0.241l0.212,0.057l0.215-0.005l0.114,0.038l0.03,0.203l-0.038,0.268
l0.003,0.935l-0.218,0.501l-0.407,0.293l-0.546,0.092l-0.21-0.13l-0.253-0.217l-0.258-0.043l-0.223,0.39l-0.04,0.483l0.182,0.228
l0.293,0.127l0.463,0.317l0.129,0.022l0.111,0.033l0.111,0.157l0.02,0.173l-0.061,0.195l-0.083,0.13l-0.063-0.022l0.051,0.38
l-0.01,0.298l0.068,0.236l0.311,0.225l-0.187,0.393l0.21,0.179l0.357,0.117l0.258,0.217l0.081,0.467l-0.218,0.176l-0.369,0.081
l-0.364,0.187l-0.324,0.407l0.121,0.13l0.336,0.09l0.311,0.285l0.081,0.38l-0.081,1.42l0.774-0.828l0.926-3.951l0.046,0.581
l-0.22,2.154l-0.296,0.966l0.258,2.922l0.979,2.559l1.884,3.259l0.453,0.574l0.41,0.242l0.964,2.283l0.076,1.426l-0.152,2.905
l0.288,1.441l0.931,2.06l0.126,0.551l0.147,2.127l0.321,1.59l0.276,2.802l-0.928,8.623l-0.008,1.433l0.182,0.821l0.44,0.394
l0.556,0.219l0.521,0.29l0.599,1.106l0.448,0.504l0.205-0.356l-0.089-1.472l0.228-0.498l0.711-0.432l-0.726-1.22l-0.42-1.641
l-0.008-1.646l0.534-1.246l-0.127-0.306l0.018-0.249l0.121-0.213l0.2-0.172l0.364,0.992l0.288,0.227l0.41-0.536l-0.776-1.412
l-0.286-0.188l0.157-0.401l0.675-0.97l0.028-0.194l-0.035-0.232v-0.202l0.126-0.082l0.132-0.044l0.182-0.096l0.157-0.107
l0.066-0.076l0.137-0.393l0.319-0.145l0.359,0.035l0.245,0.137l0.124,0.317l-0.01,0.393l-0.114,0.797l0.637,5.661l0.243-0.574
l0.121-0.579l0.119-1.875l0.276-0.809l0.091-0.563h0.21l-0.422,4.148v0.678l0.212,2.21l-0.076,0.717l-1.54,5.212l-0.101,4.215
l0.974,7.337l0.531,1.327l0.303-0.42l0.089-0.198l0.03-0.305l0.541,0.244l0.506,0.011l0.253-0.181l-0.235-0.324v-0.211l0.635,0.296
l-0.162,0.975l-1.128,2.324l-0.688,1.003l-1.207,3.011l-0.564,1.001l-0.217,0.539l-0.084,0.682l0.068,0.71l0.303,1.244l0.051,0.694
l-0.225,1.421l-1.806,5.531l-0.498,4.42v2.919l-0.071,0.685l-0.202,0.746l-0.276,0.298l-0.319-0.688l0.03-0.646l0.137-0.605
l-0.121-0.447l-0.766-0.174l-0.516,0.124l0.101,0.273l0.339,0.249l0.182,0.069l0.187,0.785l0.025,0.373l-0.068,0.644l-0.288,1.332
l-0.068,0.702l0.053,1.12l-0.053,0.418l-0.106,0.346l-0.238,0.473l-0.081,0.343l0.02,0.205l0.139,0.656l0.033,0.407l-0.066,0.387
l-0.288,0.648l-0.071,0.368l0.273-0.03l1.447-1.732l-0.501-1.173l-0.051-0.625l0.316-0.523l0.455-0.086l0.448,0.271l0.814,0.744
l0.589,0.279l0.364,0.005l0.2-0.304l0.233-2.384l-0.129-0.382v-0.251l1.272,0.318l0.329,0.382l0.144,1.507l0.094,0.263l0.195,0.102
l0.061,0.174l-1.788,5.89l-0.076,0.692l0.066,0.443l0.293,0.693l0.063,0.382l-0.104,0.313l-0.223,0.233l-0.228,0.191l-0.099,0.175
l-0.164,0.674l-0.367,0.638l-4.798,7.131l-3.518,3.867l-0.089,0.339l-0.01,0.445l-0.068,0.339l-0.159,0.3l-2.307,2.297
l-9.144,4.907l-5.076,2.443l-5.168,1.318l-0.078,0.034l-0.957-1.681l-0.935-0.377l-2.43-0.081l-0.541-0.136l-0.28-0.338
l-0.183-0.373l-0.125-0.479l-0.058-0.483l-0.116-0.509l-0.048-0.385l0.27-0.286l0.385-0.263l4.428-2.319l0.164-0.182l-0.116-0.24
l-2.345-2.398l-0.289-0.505l0.149-0.415l0.246-0.337l0.941-0.949l0.222-0.357l-0.005-0.191l-0.096-0.132l-0.154-0.069l-0.169-0.041
l-0.183,0.025l-0.806,0.393l-0.174,0.062l-0.197,0.032l-0.626,0.027l-0.194,0.048l-0.438,0.27l-0.183,0.042l-0.4,0.009
l-0.212,0.027l-0.178,0.064l-0.416,0.298l-0.159,0.083l-0.174,0.055l-0.361,0.058l-0.063,0.016l-0.063,0.027l-0.26,0.154
l-0.135,0.104l-0.318,0.173l-0.368,0.122l-1.315,0.27l-1.283,0.443l-0.222-0.083l-0.226-0.286l-3.819-6.659l-0.132-0.545
l-0.068-0.518l-0.145-7.332l-0.087-0.058l-0.303,0.056l-0.173-0.019l-0.159-0.063l-0.308-0.153l-0.169-0.06l-0.178-0.042
l-0.214,0.032l-0.207,0.09l-0.217,0.222l-0.072,0.018l-0.034-0.09l0.005-0.313l-0.019-0.253l-0.236-0.999l-0.01-0.202l0.019-0.202
l0.101-0.345l0.227-0.505l0.039-0.067l0.034-0.042l0.048-0.139l0.039-0.206l-0.039-0.353l0.082-0.631l-0.212-2.319l0.019-0.193
l0.053-0.165l0.048-0.241l0.014-0.316l-0.13-0.63l-0.034-0.337l0.029-0.248l0.116-0.377l-0.019-0.537l0.082-0.595l0.029-0.849
l0.063-0.168l0.361-0.551l0.035-0.163l0.022-0.389l0.083-0.147l0.222-0.258l0.082-0.168l0.014-0.245l-0.072-0.237l-0.469-0.886
l-0.135-0.163l-0.149-0.063l-0.169,0.014l-0.164,0.063l-0.304,0.174l-0.178,0.068l-0.178,0.026l-0.212-0.074l-0.074-0.147
l-0.013-0.182l0.058-0.168l0.082-0.144l0.525-0.428l0.106-0.121l0.096-0.138l0.145-0.314l0.053-0.17l0.082-0.146l0.14-0.095
l0.159-0.084l0.149-0.116l0.108-0.14l0.039-0.238l-0.096-0.193l-0.185-0.263l-0.077-0.198l0.01-0.181l0.077-0.14l0.116-0.133
l0.111-0.151l0.077-0.214l-0.061-0.254l-0.416-0.827l-0.198-0.231l-0.222-0.11l-0.414,0.005l-0.173-0.021l-0.169-0.058
l-0.043-0.028l-0.055-0.059l-0.138-0.201l-0.088-0.279l-0.096-0.469l-0.101-0.247l-0.135-0.186l-0.318-0.287l-0.077-0.179
l-0.019-0.189l0.005-0.193l-0.043-0.28l-0.342-0.585l-0.087-0.252l-0.043-0.236l-0.01-0.208l0.019-0.194l0.067-0.154l0.121-0.095
l0.164-0.031l0.357-0.009l0.898-0.283l0.149-0.105l0.091-0.147l0.034-0.215l-0.072-0.142l-0.111-0.126l-0.332-0.194l-0.194-0.152
l-0.265-0.297l-0.111-0.229l-0.063-0.241v-0.937l-0.048-0.43l-0.121-0.294l-0.414-0.711l-0.096-0.299l-0.043-0.281v-0.528
l-0.053-0.199l-0.072-0.173l-0.209-0.271l-0.111-0.122l-0.101-0.135l-0.703-1.303l-0.193-0.288l-0.188-0.229l-0.169-0.138
l-0.363-0.407l-0.159-0.115l-0.164-0.058l-0.227-0.105l-0.241-0.166l-0.332-0.445l-0.371-0.407l-0.262-0.201l-0.116-0.115
l-0.313-0.4l-0.125-0.105l-0.169-0.107l-0.169-0.14l-0.202-0.232l-0.048-0.208l0.01-0.606l-0.077-0.611l-0.207-0.667l-1.095-1.549
l-0.496-0.919l-0.411-0.525l-0.241-0.227l-0.694-0.455l-0.13-0.106l-0.202-0.265l-0.178-0.298l-0.209-0.261l-0.251-0.218
l-0.564-0.354l-0.737-0.648l-0.267-0.155l-0.207-0.096l-0.925-0.651l-0.58-0.583l-0.573-0.826l-0.26-0.47l-0.376-0.55l-0.212-0.251
l-0.12-0.111l-0.411-0.28l-0.178-0.151l-0.198-0.209l-1.862-2.939l-0.323-0.807l-0.087-0.426l-0.019-0.219l-0.005-0.212
l0.299-1.912l0.328-1.018l0.029-0.189l0.063-1.258l0.043-0.181l0.077-0.146l0.111-0.116l0.28-0.193l0.898-0.306l0.154-0.087
l0.116-0.116l0.053-0.17l0.019-0.198l-0.072-0.646l-0.342-1.356l-0.029-0.43l0.043-0.609l-0.039-0.628l-0.149-0.812l-0.01-0.217
l0.487-2.17l0.096-0.834l-0.014-0.267l-0.058-0.376l-0.988-1.53l-0.13-0.509l-0.048-0.501l0.003-0.837l-0.042-0.177l-0.058-0.099
l-2.123-1.694l-3.174-4.023l-0.13-0.23l-0.058-0.237l0.005-0.41l0.072-0.368l0.111-0.344l0.016-0.272l-0.05-0.382l-0.419-1.746
l-0.015-0.214l0.01-0.207l0.024-0.192l0.043-0.182l0.058-0.166l0.159-0.29l0.096-0.133l0.241-0.436l0.642-0.741l0.487-0.439
l0.217-0.238l0.202-0.263l0.493-0.864l0.92-1.14l0.154-0.299l0.14-0.345l0.182-0.72l0.109,0.043l0.737,0.035l0.649-0.202
l1.155-0.589l1.269-0.288l0.654-0.406l2.123-1.698l1.087-0.385l1.243-0.138l5.392,0.243l1.747-0.305l0.774-0.024l0.662,0.193
l0.28,0.53l0.164,0.185l0.35-0.065l0.332-0.155l0.108-0.088l0.223-0.1l0.122-0.243l0.082-0.302l0.104-0.266l0.867-1.238
l0.636-0.723l0.532-0.317l1.558-0.14l3.564-0.317l0.381,0.19l0.403,0.335l0.516,0.136l0.726-0.412l0.87-1.048l0.626-0.321
l0.74,0.324l0.946,0.831l0.493,0.214h0.548l0.972-0.61l0.895-1.12l1.356-2.181l0.869-0.703l3.607-1.118l1.497-1.188l0.519-0.176
l0.39-0.055l0.304-0.083l0.278,0.014l1.362,1.104l0.318,0.157l0.993-0.146l0.667-0.725l0.512-0.799l1.68-1.157l0.201-0.253
l0.14-0.275l0.337-0.212l3.18-1.332l2.419-0.373l0.414-0.119l0.654-0.485l0.533-0.057L386.995,369.033z M398.093,388.923
l0.354-0.653l0.281,0.022l0.169,0.511l0.043,0.805l-0.19,2.416l-1.1,4.666l-0.635,0.052l-0.243-0.471l-0.015-0.664l0.339-2.543
							L398.093,388.923z" />

						   <path class="st0" d="M418.715,298.165v-0.146l-0.288-0.112l-0.296,0.048l-0.177,0.123l-0.936,1.048l-1.487,2.229l-0.655,0.497
l-1.019,0.134l0.187-0.668l-0.121-0.356l-0.309-0.024l-0.377,0.353l-0.182,0.476l-0.129,0.588l-0.187,0.562l-0.367,0.391
l-0.668,0.155l-0.372-0.278l-0.283-0.391l-0.4-0.176l-0.299,0.075l-0.268,0.163l-0.185,0.254l-0.076,0.324l0.104,0.321l0.233-0.061
l0.283-0.161l0.207,0.021l-0.19,0.522l-4.477,3.09l-0.281,0.327l-0.478,0.717l-0.319,0.284l-0.392-0.099l-0.43,0.434l-0.665,1.24
l0.225-0.118l0.177-0.046l0.448-0.062v0.225l-0.862,0.487l-0.822,0.667l-1.518,1.543h0.637l-0.261,0.453l-1.386,1.608l-0.228,0.161
l-0.357,0.064l-0.271,0.11l-0.116,0.26l-0.086,0.327l-0.172,0.306l-1.601,2.148l-3.164,3.396l-3.647,2.95l-2.975,3.132
l-1.078,0.476l-0.779,0.218l-1.695,1.766l-0.941,0.723l-0.283-0.344l-0.354,0.247l-0.845,1.019l-1.695,1.127l-0.981-0.387
l-0.263-0.239l-0.225-0.5l-0.167-0.949L378,330.376l-0.23-0.194l-0.26-0.175l-1.315-1.18l-1.487-0.554l-0.352-0.247l-1.227-1.859
l-0.258-0.29l-0.47-0.22l-0.207-0.51l-0.114-0.602l-0.182-0.47l-0.303-0.212l-0.493-0.215l-0.546-0.167l-0.463-0.064l-0.478,0.209
l-0.326,0.918l-0.382,0.204v0.244l0.486-0.032l0.592-0.529l0.526-0.132l0.546,0.073l0.44,0.22l0.293,0.387l0.218,1.021l0.268,0.505
l1.649,2.423l0.192,0.124l0.314,0.113l0.551,0.113l0.283,0.108l0.192,0.263l0.278,0.532l0.729,0.833l0.124,0.296l-0.144,0.874
l-0.478,0.912l-0.698,0.589l-0.804-0.099l0.078,0.567h0.293l0.372-0.234l0.316-0.129l0.551,0.169l0.116,0.266l-0.243,0.942
l0.018,0.535l0.205,1.068l0.025,0.425l-0.035,0.275l-0.058,0.178l-0.099,0.153l-0.162,0.194l-0.147,0.315l0.058,0.283l0.124,0.267
l0.071,0.267l-0.043,0.724l0.043,0.323l0.094,0.248l0.273,0.485l0.099,0.498l0.152,0.488l0.023,0.291l-0.083,0.275l-0.25,0.312
l-0.058,0.226l-0.263,0.396l-0.602-0.175l-0.974-0.582l-0.516,0.135l-0.438,0.202l-0.352-0.019l-0.266-0.517l0.035,0.248
l-0.005,0.189l-0.03,0.237l0.481,0.555l2.256,1.256v0.205l-0.256,0.27l-0.235,0.399l-0.205,0.469l-0.331,1.111l-0.142,0.345
l-0.635,0.707l-0.167,0.485l0.731,0.515l-0.147,0.356l-0.481,0.353l-0.407,0.148l-0.412-0.073l-0.516-0.335l0.111,0.488
l0.167,0.488l0.217,0.432l0.258,0.329l0.405,0.286l0.663,0.124l0.321,0.157l0.094,0.227l0.126,0.483l0.038,0.553l-0.152,0.448
l-1.229,1.053l-0.149,0.405l1.09-0.573l0.65-0.157l0.486,0.173l0.078,0.859l0.225,0.329l0.476-0.384l0.253,0.694l0.415,0.311
l0.536,0.232l0.572,0.462l0.324,0.408l0.235,0.427l0.144,0.524l0.053,0.692l0.167,0.308l0.888-0.654l0.41,0.243l-0.03,0.525
l-0.41,0.316l-0.354,0.354l0.137,0.619l0.177-0.284l0.248-0.189l0.314-0.124l0.364-0.084l1.482,0.114l0.159,0.276l1.014,0.86
l0.124,0.325l0.023,0.471l-0.046,0.885l0.339-0.203l0.268,0.019l0.182,0.225l0.061,0.414L384.142,364l-0.438,0.449l-0.099,0.352
l0.192,0.579l0.951,0.893l0.364,0.471l-0.212,0.395l-0.164,0.444l-0.083,0.471l0.015,0.504l0.87-0.715l0.44-0.106l0.617,0.366
l0.212,0.189l0.053,0.081l0.03,0.119l0.129,0.314l-0.025,0.225l-0.18-0.001l-0.533,0.057l-0.654,0.485l-0.414,0.119l-2.419,0.373
l-3.18,1.332l-0.337,0.212l-0.14,0.275l-0.201,0.253l-1.68,1.157l-0.512,0.799l-0.667,0.725l-0.993,0.146l-0.318-0.157
l-1.362-1.104l-0.278-0.014l-0.304,0.083l-0.39,0.055l-0.519,0.176l-1.497,1.188l-3.607,1.118l-0.869,0.703l-1.356,2.181
l-0.895,1.12l-0.972,0.61h-0.548l-0.493-0.214l-0.946-0.831l-0.74-0.324l-0.626,0.321l-0.87,1.048l-0.726,0.412l-0.516-0.136
l-0.403-0.335l-0.381-0.19l-3.564,0.317l0.169-2.342l0.077-0.434l0.159-0.472l0.145-0.145l0.467-0.258l0.116-0.117l0.058-0.188
l0.014-0.246l-0.063-0.374l-0.01-0.308l0.048-0.3l0.18-0.362l0.154-0.193l0.173-0.124l0.337-0.136l0.622-0.324l0.26-0.203
l0.111-0.117l0.096-0.133l0.083-0.145l0.178-0.487l0.034-0.203l-0.005-0.239l-0.096-0.339l-0.14-0.217l-0.204-0.231l-3.129-2.44
l-0.315-0.103l-2.512-0.213l-0.39-0.069l-0.251-0.143l-0.103-0.346l-0.138-6.835l-0.055-0.438l-0.13-0.392l-0.337-0.28
l-3.699-1.343l-0.434-0.237l-7.785-8.188l-0.106-0.434l-0.024-0.439l0.024-0.404l-0.014-0.302l-0.048-0.212l0.01-0.331l0.096-0.457
l0.618-1.668l0.029-0.185l-0.039-0.231l-0.063-0.182l-0.17-0.315l-0.385-0.534l-0.087-0.156l-0.092-0.387l-0.063-0.661
l-0.043-0.199l-0.063-0.166l-0.096-0.134l-0.26-0.195l-0.801-0.305l-0.12-0.108l-0.087-0.14l-0.776-2.781l0.019-0.2l0.111-0.19
l2.87-2.395l0.154-0.229l0.308-0.301l0.13-0.098l0.149-0.087l0.159-0.073l0.357-0.118l0.318-0.147l0.141-0.089l0.323-0.355
l0.13-0.103l0.149-0.084l0.357-0.106l0.207-0.017l0.604,0.012l0.188-0.033l0.173-0.06l0.718-0.441l0.159-0.055l0.164-0.005
l0.149,0.055l0.112,0.111l0.087,0.143l0.14,0.581l0.077,0.154l0.135,0.089l0.308,0.135l0.14,0.08l0.246,0.207l0.593,0.653
l0.035,0.027l0.077,0.044l0.14,0.031l0.361,0.01l0.125,0.015l0.092-0.07l0.814-1.407l0.039-0.036l0.064-0.041l0.424-0.186
l0.761-0.142l0.207-0.079l0.096-0.06l0.058-0.043l0.349-0.331l0.173-0.118l0.236-0.128l1.026-0.395l0.262-0.197l0.101-0.094
l0.101-0.137l0.246-0.502l0.125-0.189l0.149-0.152l0.773-0.453l0.096-0.082l0.174-0.237l0.048-0.031l0.077-0.015l0.39-0.015
l0.323-0.056l0.53-0.22l0.125-0.077l-0.077-0.048l-0.506-0.225l-0.111-0.08l-0.072-0.072l-0.27-0.321l-0.135-0.113l-0.145-0.079
l-0.164-0.051l-0.377,0.005l-0.188-0.019l-0.164-0.063l-0.14-0.106l-0.101-0.125l-0.212-0.463l-0.111-0.109l-0.145-0.082
l-0.178-0.044l-0.667,0.01l-0.212-0.017l-0.043-0.34l-0.014-0.64l0.188-1.595l0.005-0.667l-0.039-0.421l-0.766-1.247l-0.072-0.171
l-0.053-0.174l-0.077-0.365l0.034-0.215l0.096-0.206l0.313-0.211l0.26-0.087l0.332-0.044l0.797-0.022l0.366-0.09l0.212-0.194
l0.072-0.547l0.01-8.242l3.121-11.64l0.169-1.058l-0.239,0.031l-0.378-0.143l-0.291-0.312l-0.173-0.323l-0.202-0.29l-0.371-0.216
l-0.742-0.132l-0.303-0.131l-0.283-0.334l-0.159-0.372l-0.212-0.752l-0.196-0.353l-0.294-0.273l-0.312-0.131l-0.333-0.088
l-0.356-0.143l-0.316-0.278l-0.151-0.331l-0.119-0.351l-0.212-0.351l-0.312-0.231l-1.107-0.346l-1.073-0.68l-0.439-0.108
l-0.996-0.064l-0.344-0.783l0.043-0.397l0.313-1.165l0.165-0.353l0.448-0.573l0.93-0.954l0.508-0.818l0.164-0.21l0.154-0.158
l0.27-0.183l0.323-0.357l0.241-0.369l0.189-0.125l0.177-0.052l0.165-0.02l0.164-0.112l0.525-0.715l0.111-0.212l0.005-0.32
l-0.058-0.202l-0.082-0.18l-0.183-0.274l-0.077-0.158l-0.053-0.18l-0.072-0.401l-0.072-0.152l-0.154-0.056l-0.315-0.056
l-0.071-0.124l-0.016-0.169l0.026-0.178l0.318-0.99l0.058-0.369l0.005-0.198l-0.019-0.201l-0.053-0.179l-0.13-0.328l-0.734-1.342
l-0.068-0.162l-0.024-0.183l0.048-0.213l0.864-0.932l0.357-0.531l0.106-0.118l0.506-0.402l0.744-0.406l0.39-0.296l0.332-0.338
l0.096-0.166l0.082-0.21l0.193-1.026l0.198-0.446l0.342,0.047l0.18,0.059l0.92,0.147l0.299,0.101l0.096,0.061l0.079,0.064
l0.429,0.423l0.101,0.127l0.313,0.605l0.111,0.36l0.067,0.411l0.053,0.178l0.077,0.152l0.212,0.237l0.125,0.105l0.4,0.259
l0.127,0.101l0.304,0.321l0.434,0.277l0.313,0.127l0.318,0.095l0.156,0.022l0.236-0.051l0.101-0.051l0.048-0.02l0.063-0.046
l0.949-1.317l0.337-0.282l0.411-0.063l1.311,0.183l0.503-0.5l0.4-1.016l0.742-2.861l0.141-1.316l0.01-0.919l-0.189-0.451
l-0.015-0.095l0.169-1.423l-0.063-0.336l-0.111-0.208l-0.246-0.157l-0.154-0.147l-0.39-0.471l-0.087-0.147l-0.116-0.137
l-0.173-0.142l-2.567-0.979l-0.299-0.238l-0.077-0.37l0.01-0.423l0.419-3.166l-0.058-0.447l-0.906-3.473l-0.034-0.462l0.072-0.416
l1.254-3.037l0.116-0.433l-0.006-1.704l0.243-1.641l0.443-1.378l0.559-1.228l0.236-0.685l0.082-0.609l-0.106-1.84l0.014-0.222
l0.034-0.222l0.072-0.251l1.844-3.844l0.198-0.252l0.487-0.37l0.93-0.507l0.262-0.101l1.558-0.396l2.276-1.077l0.202-0.144
l0.631-0.544l0.352-0.373l0.358-0.568l0.588-1.331l1.857-2.157l2.334,1.285l2.364,0.678l0.726,0.359l2.804,2.371l0.26,0.327
l0.442,0.982l0.765,3.238l1.243,2.111l0.594,1.359l0.361,1.249l0.063,0.653l0.169,0.037l0.121,0.298l0.506,0.831l0.114,0.454
l0.064,0.16l0.324,0.444l0.906,2.255l0.273,1.387l0.136,0.263l0.484,0.535l0.161,0.251l0.402,1.16l0.035,0.185l-0.003,0.617
l0.042,0.202l0.145,0.296l0.41,0.549l0.5,0.532l0.972,0.676l0.803,0.741l0.297,0.135l0.226,0.351l0.398,0.292l1.996,1.02
l0.704,0.57l0.36,0.226l0.39,0.25l0.342,0.293l1.033,1.297l0.414,0.238l0.183,0.317l0.536,0.471l0.14,0.253l0.169,0.041
l0.183,0.533l0.194,1.168l0.157,0.533l0.26,0.356l1.139,0.631l0.381,0.341l0.26,0.373l0.373,0.885l0.255,0.385l0.347,0.223
l0.393,0.172l0.392,0.236l0.956,0.947l0.419,0.176h0.4l0.789-0.203l0.297-0.041l0.451,0.132l0.74,0.441l0.981,0.236l0.63,0.353
l1.132,0.968l1.548,0.72l0.615,0.525l-0.331,0.663l0.816,0.83l0.227,0.287l0.472,0.878l0.202,0.238l-0.082,0.84l0.626,0.648
l1.903,0.962l1.333,0.496l0.329,0.228l0.421,0.563l0.331,0.327l0.665,0.327l0.45,0.54l0.313,0.609l0.27,0.76l0.178,0.797
l0.149,0.396l0.212,0.169l0.015,0.266l0.43,1.248l0.202,0.388l0.145,0.371l-0.038,1.511l0.146,0.452l0.159,0.354l-0.074,0.229
l-0.549,0.083l-0.594,0.453l-0.122,1.019l0.149,1.071l0.228,0.614l-0.586,0.273l0.122,0.327l0.57,0.141l0.763-0.293l0.543,0.806
							l0.556,0.605l0.048,0.202l-0.549,0.836l-0.363,0.355l-0.427,0.249l-0.443,0.105l-0.042,0.03L418.715,298.165z" />


						   <path class="st0" d="M555.282,126.655l0.541,0.45l0.455,0.583l0.481,0.499l0.642,0.213l0.038,0.208l-0.407,0.489l-1.019,0.952
l-0.347,0.627l0.101,0.718l0.85,1.84l0.154,0.581l0.486,3.8l0.402,0.86l0.02,0.349l0.063,0.352l0.483,0.469l0.207,0.268
l0.172,0.302l0.071,0.258l0.071,0.798l0.316,1.405l0.058,0.66l-0.152,0.858l-0.354,0.386l-1.214,0.469l0.357,0.214l0.304,0.303
l0.159,0.381l-0.066,0.443l-0.306,0.365l-0.402,0.146l-0.895,0.136l-0.911,0.548l0.212,0.54l0.455,0.657l-0.182,0.906l0.425-0.138
l0.349-0.613l0.278-0.016l0.213,0.271l-0.096,0.323l-0.051,0.324l0.369,0.282l-0.465,0.35l-0.192,0.089l0.329,0.193l0.324-0.005
l0.319-0.157l0.324-0.251l-0.008-0.934l0.771-0.6l1.528-0.642l0.38,0.261l0.379,0.451l0.172,0.483l-0.066,0.973l0.162,0.36
l0.521,0.305l-0.344,0.459l-0.149,0.605l-0.008,0.626l0.076,0.522l-0.425,0.141l-0.734,0.59l-0.329,0.141l-0.43-0.131l-0.594-0.627
l-0.478-0.115l0.632,3.504l-0.551-0.18l-0.286,0.204l0.03,0.361l0.387,0.29l-0.637,0.658l0.061,0.178l0.142,0.172l0.147,0.104
l0.076-0.016l-0.233,1.489l0.02,0.502l0.658-0.619l0.458-0.745l0.85-2.701l0.245-0.246l1.872-0.909l0.39-0.078l0.875,0.303
l0.837,0.755l0.44,0.969l-0.326,0.951l-0.405,0.272l-0.951,0.219l-0.47,0.175l-0.412,0.363l0.03,0.282l0.334,0.154l0.473-0.018
l0.316-0.225l0.281-0.319l0.309-0.162l0.389,0.246l0.159,0.447l-0.048,0.549l-0.648,1.835l0.058,0.345l0.266,0.549l0.101,0.107
l0.182,0.128l0.195,0.18l0.159,0.267l0.038,0.209l0.003,0.442l0.066,0.222l0.071,0.507l-0.43,1.883l-0.061,0.178l0.015,0.157
l0.192,0.238l0.111,0.186l0.003,0.183l-0.076,0.173l-0.144,0.131l-1.487,0.631l-0.233,0.243l-0.154,0.275l-1.115,1.164
l-0.405,0.406l-0.513,0.27l-0.562,0.154l-0.546,0.047l-0.26-0.196l-0.346-0.974l-0.357-0.366l0.121,0.607l0.035,0.607l0.172,0.544
l0.536,0.437l0.496,0.081l1.477-0.003l0.364-0.078l0.319,0.516l0.334,0.717l0.228,0.707l-0.015,0.495l-0.463-0.39l-0.316-0.034
l-0.299,0.123l-0.405,0.084l-1.72-0.461l-0.278,0.586l0.392,0.924l1.161,1.587l-0.966,0.346l-0.969,0.128l-0.852,0.335
l-0.627,0.967l-0.448-0.17l-0.63-0.144l-0.627-0.044l-0.435,0.12l-0.162,0.333l-0.258,1.166l-0.218,0.477l1.108,0.173l0.551,0.197
l0.46,0.312l0.268-0.972l0.625-0.377l0.802,0.107l0.776,0.461l0.428,0.713l0.233,1.028l-0.129,0.899l-0.65,0.343l0.395,0.488
l-0.076,0.404l-1.164,1.282l-0.142,0.212l0.056,0.509l-0.096,0.27l-0.162,0.275l-0.177,0.215l-0.597,0.556l-0.157,0.205
l-0.053,0.241l0.048,0.522l-0.111,0.239l-0.258,0.43l-0.111,0.488l0.106,0.336l0.379-0.031l-0.205,0.467l-0.266,0.415l-0.354,0.352
l-0.471,0.305l-0.415,0.181l-0.283,0.024l-0.784-0.205l-0.096,0.845l-0.473,0.567l-1.244,0.911l-0.463,0.57l-1.932,3.221
l-6.187,6.488l-1.315,0.776l-0.192,0.284l-0.066,0.408l-0.132,0.363l-0.352,0.16l-0.288,0.045l-0.402,0.126l-0.349,0.216
l-0.129,0.295l0.349,0.363l0.668-0.374l1.105-1.113l0.177,0.742l-0.248,0.568l-0.359,0.524l-0.187,0.613l-0.139,0.803l-0.364,0.5
l-1.002,0.927l-1.29,2.486l-0.837,1.212l-1.075,0.522l-1.991,0.037l-0.574,0.166l-1.925,1.041l-0.931,0.809l-0.076,0.14
l0.051,0.279l0.212,0.116l0.349,0.048l0.02,0.382l-0.046,0.799l0.025,0.385l0.228,0.67l0.394,0.575l0.511,0.448l0.587,0.301
l-1.411,0.902l-0.521,0.2l-0.035-0.229l-0.157-0.425l-0.038-0.232l-0.296,0.142l-0.308,0.079l-0.319,0.026l-0.329-0.026l0.162,0.15
l0.293,0.361l0.162,0.153l-0.835,0.301l-0.314,0.198l-0.334,0.388l0.041,0.058l0.035,0.203l-0.025,0.261l-0.149,0.253l-0.524,0.554
l-0.617,0.451l-1.272,0.72l-0.926,0.765l-1.252,0.407l-0.299,0.211l-0.784,0.808l-5.481,2.857l-4.72,3.022l-0.524,0.454
l-0.809,1.197l-0.435,0.286l-0.569-0.286l-0.003,0.13l-0.044-0.044l-0.034-0.317l0.067-0.811l-0.005-0.2l-0.024-0.185l-0.082-0.163
l-0.251-0.336l-0.096-0.161l-0.202-0.478l-0.792-0.983l-0.145-0.312l-0.087-0.27l-0.024-0.178l-0.284-0.594l-0.255-0.409
l-0.324-0.77l-0.169-0.277l-0.159-0.146l-0.226-0.156l-0.867-0.389l-0.127-0.092l-0.154-0.156l-0.193-0.33l-0.092-0.236
l-0.058-0.225l-0.034-0.387l0.048-1.074l0.043-0.189l0.067-0.162l0.087-0.136l0.106-0.124l0.111-0.092l0.329-0.164l0.323-0.097
l0.492-0.084l0.193-0.07l0.149-0.095l0.096-0.129l0.063-0.163l0.024-0.186l0.019-0.405l-0.039-0.436l-0.043-0.231l-0.005-0.094
l-0.101-0.69l-0.183-0.687l-0.12-0.315l-0.164-0.276l-0.376-0.512l-0.077-0.144l-0.058-0.156l-0.183-0.896l-0.149-0.417
l-0.045-0.09l-0.347-0.512l-0.236-0.636l-0.149-0.295l-0.347-0.532l-0.385-0.487l-0.154-0.285l-0.103-0.335l-0.294-0.577
l-0.4-1.127l-0.043-0.169l0.005-0.197l0.063-0.395l0.01-0.204l-0.024-0.383l0.019-0.194l0.043-0.172l0.323-0.59l0.058-0.165
l0.048-0.181l0.026-0.384l-0.03-0.184l-0.043-0.167l-0.602-1.576l-0.665-1.1l-0.633-0.792l-0.178-0.291l-0.072-0.164l-0.024-0.175
l0.005-0.195l0.077-0.379l0.014-0.204l-0.005-0.192l-0.039-0.175l-0.067-0.15l-0.145-0.185l-0.535-0.588l-0.096-0.237l-0.029-0.197
l0.019-0.16l-0.053-0.195l-0.12-0.202l-0.251-0.289l-0.117-0.219l-0.067-0.202l-0.159-0.703l-0.053-0.16l-0.077-0.145l-0.631-0.915
l-0.197-0.461l-0.154-0.275l-0.227-0.19l-1.651-1.108l-0.096-0.127l-0.106-0.118l-0.405-0.285l-0.12-0.105l-0.101-0.12l-0.14-0.299
l-0.125-0.519l-0.058-0.157l-0.198-0.155l-0.308-0.14l-1.524-0.374l-1.428-0.644l-0.405-0.243l-0.241-0.217l-0.055-0.16l-0.1-0.537
l-0.175-0.482l-0.077-0.142l-0.207-0.237l-0.121-0.105l-0.265-0.188l-0.217-0.075l-0.777-0.15l-0.308-0.113l-0.462-0.335
l-0.559-0.332l-0.106-0.153l-0.079-0.163l-0.096-0.337l-0.111-0.15l-0.159-0.14l-0.294-0.152l-0.284-0.192l-0.038-0.043
l-0.101-0.147l-0.202-0.22l-0.371-0.273l-0.222-0.193l-0.117-0.082l-0.93-0.488l-0.164-0.055l-0.236,0.033l-0.705,0.192
l-0.371,0.055l-0.198,0.005l-1.654-0.245l-0.308-0.105l-0.501-0.255l-0.454-0.295l-0.414-0.481l-0.164-0.27l-0.284-0.59
l-0.082-0.133l-0.14-0.125l-0.45-0.286l-0.101-0.165l-0.014-0.177l0.043-0.165l0.019-0.175l-0.043-0.155l-0.125-0.142l-0.535-0.303
l-0.357-0.253l-0.4-0.16l-0.349-0.075l-0.434-0.36l-0.323-0.198l-1.543-0.619l-0.429-0.245l-0.257-0.213l-0.067-0.151l-0.08-0.14
l-0.103-0.12l-0.357-0.316l-0.106-0.115l-0.164-0.25l-0.149-0.102l-0.212-0.083l-1.12-0.02l-0.357,0.15l-0.178,0.025l-0.222-0.045
l-0.275-0.155l-0.18-0.122l-0.13-0.133l-0.202-0.243l-0.439-0.663l-0.101-0.117l-0.173-0.098l-0.241-0.06l-0.439,0.01l-0.252,0.048
l-0.193,0.076l-0.255,0.188l-0.28,0.158l-0.159,0.052l-0.188,0.033l-0.472,0.03l-0.185,0.055l-0.14,0.1l-0.308,0.366l-0.116,0.103
l-0.834,0.479l-0.324,0.108l-0.178,0.03l-0.236-0.018l-0.26-0.077l-0.342-0.198l-0.313-0.31l-0.165-0.09l-0.222-0.053l-0.366-0.03
l-0.227-0.087l-0.207-0.235l-0.12-0.416l-0.154-0.155l-0.265-0.141l-0.517-0.188l-0.236-0.16l-0.159-0.155l-0.072-0.148
l-0.337-0.536l-0.13-0.135l-0.188-0.12l-0.512-0.273l-0.241-0.075l-0.395,0.022l-0.217,0.05l-0.154,0.06l-0.439,0.331l-0.212,0.236
l-0.604,1.007l-0.193,0.248l-0.12,0.1l-0.14,0.082l-0.154,0.067l-0.164,0.047l-0.546,0.095l-0.159,0.055l-0.703,0.389l-0.149,0.038
l-0.173-0.045l-0.18-0.203l-0.106-0.176l-0.149-0.321l-0.101-0.12l-1.026-0.501l-0.236-0.183l-0.145-0.18l-0.623-1.744
l-0.159-0.283l-0.212-0.231l-0.092-0.128l-0.072-0.146l-0.077-0.346l-0.058-0.16l-0.072-0.145l-0.183-0.26l-0.421-0.467
l-0.463-0.424l-0.52-0.388l-0.217-0.226l-15.544-0.238l1.616-1.613l0.419-0.637l0.072-0.231l0.231-0.407l1.384-1.443l0.289-0.623
l0.125-0.337l0.49-0.975l0.827-1.103l1.664-0.679l0.197-0.211l0.182-0.319l0.92-1.011l0.384-0.314l0.308-0.131l0.659-0.169
l0.326-0.139l0.516-0.42l0.275-0.171l0.376-0.066l0.599-0.229l2.025-1.693l0.108-0.141l0.177-0.103l0.381-0.046l0.379,0.065
l0.601,0.319l0.188,0.075l0.601-0.118l3.175-1.766l1.083-0.908l0.89-0.995l1.11-1.539l1.209-1.255l0.469-0.254h2.449l0.586-0.403
l0.712-0.734l0.514-0.796l-0.008-0.585l-0.289-0.665l0.376-0.462l0.678-0.258l0.615-0.058l2.44,0.219l0.442-0.199l0.808-0.976
l0.554-0.358l1.346,0.013l0.72,0.825l0.646,1.008l1.129,0.563l0.477-0.128l0.567-0.342l0.475-0.403l0.198-0.323l0.3-0.197
l5.233-0.993l0.63-0.467l1.071-1.11l1.222-0.644l2.694-0.313l1.216-0.354l0.959-0.439l0.951-0.18l0.949,0.149l1.172,0.47
l1.208,0.328l3.905-0.109l0.732-0.113l1.038-0.604l0.458-0.139l2.088-0.268l0.585-0.189l1.717-1.325l0.983-0.331l1.331-0.949
l1.857-0.409l0.303-0.159l0.167-0.76l0.437-0.614l0.605-0.452l0.681-0.271l1.37-0.154l0.801,0.048l1.142,0.477l0.734-0.316
l1.084-0.908l1.097-0.688l0.695-0.301l0.639-0.124l0.252-0.103l0.312-0.452l0.284-0.101l1.177-0.218l0.634-0.28l0.697-0.452
l0.559-0.554l0.231-0.584l0.193-0.357l0.464-0.347l0.559-0.296l0.482-0.19l0.975-0.147l0.318-0.091l0.209-0.157l0.206-0.218
l0.239-0.197l0.31-0.084l1.274-0.084l0.581-0.134l0.596-0.218l1.789-1.337l0.66-0.192h1.293l0.988,0.218h0.382l1.017-0.552
l1.755-2.172l0.835-0.79l0.891-0.492l0.429-0.344l0.422-0.856l0.577-0.277l1.307-0.431l0.302-0.246l0.758-0.846l0.398-0.249
l0.157,0.026l0.173,0.139l1.144,0.182l0.39,0.238l0.299,0.292l0.429,0.266l1.264,0.131l2.573-0.795l1.179-0.229l1.466,0.005
l0.54,0.281l0.212,0.707l0.307,0.699l0.726,0.358l0.848,0.017L555.282,126.655z M536.09,215.598l1.058,0.111l0.087,0.742
l-0.142,0.657l-0.828,0.858l-0.256,0.943l0.058,0.571l-0.171,0.457l-0.772,0.23l-0.686-0.227l-0.744-0.627l-0.459-1.112
							l0.113-1.256l0.342-0.571l0.885-0.43L536.09,215.598z" />

					   </g>
					   
					  </svg>
					</div>
				  </div>
				  <div class="col-md-6 mb-4 mb-md-12">
					<h3 class="fw-bold">Cabo Delgado</h3>
					<div class="mb-2 text-danger small"> <i class="fas fa-globe-africa me-2"
						aria-controls="#picker-editor"></i><span>Chiúre</span> </div>
					<p class="text-muted">Were are based in Cabo Delgado</p>
				  </div>
				</div>
		
			  </section>
			</section>
		
		  </div>







        <section draggable="false" class="container pt-5 py-5" data-v-271253ee="">
          <section class="mb-10 text-center">
            <div class="row d-flex justify-content-center">
              <div class="col-md-10 col-xl-8">
                <h2 class="fw-bold mb-5">Testimonials</h2>
              </div>
            </div>
            <div class="row " >
            <div class="col-md-4 mb-5 mb-md-0">
                <div class="d-flex justify-content-center mb-4">
                  <img
                    src="https://firebasestorage.googleapis.com/v0/b/oka-ventures.appspot.com/o/site-pics%2Ftestimonials%2Fguilherme.png?alt=media&token=5f7e0e10-7244-4d60-a758-c05200fd4dcb"
                    class="rounded-circle shadow-1 imgHW"
                    alt="Guilherme"
                    aria-controls="#picker-editor"
                    hidden
                  />
                </div>
                <h5 class="mb-3" hidden>Guilherme</h5>
                <h6 class="text-primary mb-3" hidden>Driver</h6>
                <p hidden class="px-xl-3"> <i class="fas fa-quote-left pe-2"></i><span>Oka Taxi has changed my life. I used to
                    work in agriculture and live with my parents without regular income. Today, I am a respected and
                    active member of the community living in my own house with a corrugated iron roof! I am proud of
                    every life I save.</span> </p>

              </div>
              <div class="col-md-4 mb-5 mb-md-0">
                <div class="d-flex justify-content-center mb-4">
                  <img
                    src="https://firebasestorage.googleapis.com/v0/b/oka-ventures.appspot.com/o/site-pics%2Ftestimonials%2Fguilherme.png?alt=media&token=5f7e0e10-7244-4d60-a758-c05200fd4dcb"
                    class="rounded-circle shadow-1 imgHW"
                    alt="Guilherme"
                    aria-controls="#picker-editor"
                  />
                </div>
                <h5 class="mb-3">Guilherme</h5>
                <h6 class=" mb-3 testmonial-color">Driver</h6>
                <p class="px-xl-3"> <i class="fas fa-quote-left pe-2"></i><span>Oka Taxi has changed my life. I used to
                    work in agriculture and live with my parents without regular income. Today, I am a respected and
                    active member of the community living in my own house with a corrugated iron roof! I am proud of
                    every life I save.</span> </p>

              </div>
              <div class="col-md-4 mb-5 mb-md-0">
                <div class="d-flex justify-content-center mb-4">
                  <img
                    src="https://firebasestorage.googleapis.com/v0/b/oka-ventures.appspot.com/o/site-pics%2Ftestimonials%2Fguilherme.png?alt=media&token=5f7e0e10-7244-4d60-a758-c05200fd4dcb"
                    class="rounded-circle shadow-1 imgHW"
                    alt="Guilherme"
                    aria-controls="#picker-editor"
                    hidden
                  />
                </div>
                <h5 class="mb-3" hidden>Guilherme</h5>
                <h6 class="text-primary mb-3" hidden>Driver</h6>
                <p hidden class="px-xl-3"> <i class="fas fa-quote-left pe-2"></i><span>Oka Taxi has changed my life. I used to
                    work in agriculture and live with my parents without regular income. Today, I am a respected and
                    active member of the community living in my own house with a corrugated iron roof! I am proud of
                    every life I save.</span> </p>

              </div>
            </div>
          </section>
        </section>
      </main>
      <Footer></Footer>
    </div>
  )
}

export default Home
