import React from 'react'
import { Link } from 'react-router-dom'
import './footer.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Footer = () => {
  return (
    <footer>
      <div class="footer-top">
        <div class="container">
          {/* <div class="footer-day-time">
                    <div class="row">
                        <div class="col-md-8">
                            <ul>
                                <li>Opening Hours: Mon - Friday: 7AM - 4PM</li>
                            </ul>
                        </div>
                        <div class="col-lg-4">
                            <div class="phone-no">
                                <a href="tel:+258 86 496 0453"><i class="fa fa-mobile" aria-hidden="true"></i>Call +258 86 496 0453</a>
                            </div>
                        </div>
                    </div>
                </div> */}
          <div class="row">
            <div class="col-md-4">
              <h4>Follow us</h4>
              <ul class="social-icon">
                <li>
                  <a href="https://www.youtube.com/channel/UCefxIk6AB9LqpVZD5hcLNgw" target='_blank'>
                  <i className="fab fa-youtube text-white"></i>
                  </a>
                </li>
                <li>
                  <a href="https://web.facebook.com/Oka-Ventures-107450218834257" target='_blank'>
                    <i class="fab fa-facebook text-white"></i>
                  </a>
                </li> 
                <li>
                  <a href="https://web.facebook.com/Oka-Ventures-107450218834257" target='_blank'>
                    <i class="fab fa-linkedin text-white"></i>
                  </a>
                </li> 

                {/* <li>
                  <a href="#">
                    <i class="fa fa-instagram" ></i>
                  </a>
                </li>*/}
                <li>
                  <a href="https://twitter.com/OkaVentures" target='_blank'>
                    <i class="fab fa-twitter text-white"></i>
                  </a>
                </li> 
                <li>
                  <a href="https://wa.me/+84443770" target='_blank'>
                  <i className="fab fa-whatsapp text-white"></i>
                  </a>
                </li>
              </ul>
            </div>

            <div class="col-lg-4">
              <h4>Our goals</h4>
              <p>
                We provide hands on opportunities for entrepreneurs by renting
                out specific hardware that enables them to starting up a local
                business
              </p>
            </div>

            <div class="col-md-4">
              <h4 className="text-text-lowercase">Information</h4>
              <ul class="address1">
                <li>
                  <i class="fa fa-map-marker"></i>Chiure, Cabo Delgado -
                  Mozambique
                </li>
                <li>
                  <i class="fa fa-envelope"></i>
                  <a href="mailto:#">info@okaventures.org</a>
                </li>
                <li>
                  <i class="fa fa-mobile" aria-hidden="true"></i>{' '}
                  <a href="tel:+258 84 443 3770">+258 84 443 3770</a> / <a href="tel:+258 86 044 3377">+258 86 044 3377</a>
                </li>
              </ul>
            </div>
          </div>

          <hr />
                
        </div>
      </div>

      <div class="footer-bottom">
        <div class="container">
          <div class="row">
            <div class="col-md-3">
              <p class="copyright ">Copyright © 2022</p>
            </div>
            {/* <div class="col-sm-7">
                        <ul>
                            <li><a href="#">Home</a></li>
                            <li><a href="#">About Us</a></li>
                            <li><a href="#">Our services</a></li>
                            <li><a href="#">Contact Us</a></li>
                        </ul>
                    </div> */}
          </div>
        </div>
      </div>
      {/* Back to top button */}
            {/* <button type="button" class="btn btn-dark btn-floating btn-lg" id="btn-back-to-top">
                <i class="fas fa-arrow-up"></i>
            </button> */}
    </footer>
  )
}

export default Footer
