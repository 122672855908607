import React from 'react';
import { Container } from 'react-bootstrap';

const PartenWithUs = () => {
    return (
        <Container>
             <div>
            <h1>Site in developement </h1>
            <h4>We will bring all content related to this page soon </h4>
        </div>
        </Container>
       
    );
}

export default PartenWithUs;
