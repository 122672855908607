import React from "react"
import { Routes, Route, Navigate, BrowserRouter } from 'react-router-dom';
import Home from './pages/en/Home';
import WhatWeDo from './pages/en/WhatWeDo';
import WhoWeAre from './pages/en/WhoWeAre';
import History from './pages/en/History';
import PartenWithUs from './pages/en/PartenWithUs';

import Header from './pages/en/commons/Header'
import './pages/en/commons/header.css'



function App() {
  return (
    <>
      {/* <Header />
      <h1>Home...</h1> */}
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Header />}>
            <Route index element={<Home />} />
            <Route path='what-we-do' element={<WhatWeDo />} />
            <Route path='who-we-are' element={<WhoWeAre />} />
            <Route path='parten-with-us' element={<PartenWithUs />} />
            <Route path='history' element={<History />} />
            <Route path='*' element={<Navigate replace to="/" /> }/>
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  )
}

export default App
