import React, { useEffect, useState } from 'react'
import { Navbar, Container, Nav } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import HamburgerMenu from 'react-hamburger-menu'
import { Outlet, Link } from 'react-router-dom'
import Logo from '../commons/logo.png'

const Header = () => {
  const [onScroll, setOnScroll] = useState(false)
  const [menuOpen, setMenuOpen] = useState(false)

  useEffect(() => {
    window.addEventListener('scroll', () => {
      setOnScroll(window.scrollY > 50)
    })
  }, [])

  const disableScroll = () => {
    let x = window.scrollX
    let y = window.scrollY
    window.onscroll = function () {
      window.scrollTo(x, y)
    }
  }
  const enableScroll = () => {
    window.onscroll = function () {}
  }

  useEffect(() => {
    menuOpen ? disableScroll() : enableScroll()
  }, [menuOpen])

  return (
    <>
      <Navbar
        bg={onScroll ? 'dark' : 'light'}
        variant={onScroll ? 'dark' : 'light'}
        expand="md"
        style={{ width: '100%', position: 'fixed', zIndex: 100 }}
        className={`main-header ${onScroll ? 'on-scroll-header' : ''}`}
        onToggle={() => setMenuOpen(!menuOpen)}
      >
        <Container>
          <Navbar.Brand as={Link} to="/">
              <img src={Logo} height="90px" alt="Logo" className="nav__logo" />
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="navbar"
            bsPrefix="navbar-toggler hamburger-menu"
          >
            <HamburgerMenu
              isOpen={menuOpen}
              menuClicked={() => null}
              width={25}
              height={15}
              strokeWidth={1.5}
              rotate={0}
              color={onScroll ? 'white' : 'black'}
              borderRadius={0}
              animationDuration={0.6}
            />
          </Navbar.Toggle>
          <Navbar.Collapse id="navbar">
            <Nav className="ms-auto">
              <Nav.Link as={Link} to="/what-we-do">
                What we do
              </Nav.Link>
              <Nav.Link as={Link} to="/who-we-are">
                Who we are
              </Nav.Link>
              {/* <Nav.Link as={Link} to="/parten-with-us">
                Parten with us
              </Nav.Link> */}
              <Nav.Link as={Link} to="/history">
                History
              </Nav.Link>

              {/* <Nav.Link as={Link} to="/history">
                <div class="d-flex align-items-center">
                  <a
                    style={{ backgroundColor: '#F4823C;' }}
                    class="btn ms-3 text-capitalize"
                    href="./donation.html"
                    role="button"
                  >
                    <i class="fab fa-heart me-2"></i>
                    Donate
                  </a>
                </div>
              </Nav.Link> */}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <div className="top-content">
        <Outlet></Outlet>
      </div>
    </>
  )
}

export default Header
